import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image, Container } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import parse from 'html-react-parser';
import { IoEye } from "react-icons/io5";

const AdminCeilingSolution = () => {

  //Add Modal for Slider
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setData1(item);
  };

  // Edit modal for  Slider
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (item) => {
    setCeilingDesc1(item.CeilingDesc1);
    setCeilingTitle(item.CeilingTitle);
    setCeilingDesc2(item.CeilingDesc2);
    setCeilingSliderImg1(item.CeilingSliderImg1);
    setCeilingSliderImg2(item.CeilingSliderImg2);
    setCeilingSliderImg3(item.CeilingSliderImg3);
    setCeilingSliderImg4(item.CeilingSliderImg4);
    setCeilingSliderImg5(item.CeilingSliderImg5);
    setData1(item);
    setShow4(true);
  }

  // Delet modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);


  // Image View 
  const [View, setView] = useState({});
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ======INTEGRATION=======//

  // integrating post method
  const formdata = new FormData();
  const [CeilingSliderImg1, setCeilingSliderImg1] = useState("");
  const [CeilingSliderImg2, setCeilingSliderImg2] = useState("");
  const [CeilingSliderImg3, setCeilingSliderImg3] = useState("");
  const [CeilingSliderImg4, setCeilingSliderImg4] = useState("");
  const [CeilingSliderImg5, setCeilingSliderImg5] = useState("");
  const [CeilingDesc2, setCeilingDesc2] = useState("");
  const [CeilingTitle, setCeilingTitle] = useState("")
  const [CeilingDesc1, setCeilingDesc1] = useState("");

  const AddCeilingDetails = async () => {
    formdata.append("CeilingDesc1", CeilingDesc1);
    formdata.append("CeilingTitle", CeilingTitle);
    formdata.append("CeilingDesc2", CeilingDesc2);
    formdata.append("CeilingSliderImg1", CeilingSliderImg1);
    formdata.append("CeilingSliderImg2", CeilingSliderImg2);
    formdata.append("CeilingSliderImg3", CeilingSliderImg3);
    formdata.append("CeilingSliderImg4", CeilingSliderImg4);
    formdata.append("CeilingSliderImg5", CeilingSliderImg5);

    try {
      if (!CeilingDesc1) {
        return alert("Please add Description");
      }
      if (!CeilingTitle) {
        return alert("Please add Title");
      }
      if (!CeilingDesc2) {
        return alert("Please add Description");
      }
      if (!CeilingSliderImg1) {
        return alert("Please add Image");
      }
      if (!CeilingSliderImg2) {
        return alert("Please add Image");
      }
      if (!CeilingSliderImg3) {
        return alert("Please add Image");
      }
      if (!CeilingSliderImg4) {
        return alert("Please add Image");
      }
      if (!CeilingSliderImg5) {
        return alert("Please add Image");
      }

      const config = {
        url: "/admin/ceiling",
        method: "post",
        baseURL: "https://roomyy.life/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddCeiling();
        handleClose3();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddCeiling, setAddCeiling] = useState([]);
  const getAddCeiling = async () => {
    try {
      let res = await axios.get(
        "https://roomyy.life/api/admin/getceiling"
      );
      if (res.status === 200) {
        setAddCeiling(res.data.getceiling);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const DeleteCeiling = async () => {
    try {
      const config = {
        url: "admin/Deleteceiling/" + Data,
        method: "delete",
        baseURL: "https://roomyy.life/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddCeiling();
          handleClose5();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const [show3, setShow3] = useState(false);

  const EditCeiling = async (e) => {
    e.preventDefault();
    formdata.append("CeilingDesc1", CeilingDesc1);
    formdata.append("CeilingTitle", CeilingTitle);
    formdata.append("CeilingDesc2", CeilingDesc2);
    formdata.append("CeilingSliderImg1", CeilingSliderImg1);
    formdata.append("CeilingSliderImg2", CeilingSliderImg2);
    formdata.append("CeilingSliderImg3", CeilingSliderImg3);
    formdata.append("CeilingSliderImg4", CeilingSliderImg4);
    formdata.append("CeilingSliderImg5", CeilingSliderImg5);

    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "admin/editceiling",
        method: "put",
        baseURL: "https://roomyy.life/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose4();
          getAddCeiling();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };
  useEffect(() => {
    getAddCeiling();
  }, []);
  console.log(AddCeiling);

  // pagination
  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 5;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  const records = AddCeiling.slice(firstIndex, lastIndex);
  const npages = Math.ceil(AddCeiling.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }

  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setCeilingDesc1(data);
  };

  const handleChange2 = (e, editor) => {
    const data = editor.getData();
    setCeilingDesc2(data);
  };


  return (
    <div>
      <div className="col-lg-4 d-flex justify-content-center">
        <div class="input-group ">
          <span class="input-group-text" id="basic-addon1">
            <BsSearch />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
          />
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <h2 className="header-c ">Ceiling Solution</h2>
        <div className="d-flex gap-3">
          <button className="admin-add-btn" onClick={handleShow3}>
            Add Ceiling Solution
          </button>

        </div>

      </div>

      <div className="mb-3">
        <Table
          responsive
          bordered
          style={{ width: "-webkit-fill-available" }}
        >
          <thead>
            <tr>
              <th>Description</th>
              <th>Title</th>
              <th>Description</th>
              <th>Slider Image</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {records?.map((item, i) => {
              return (
                <tr key={i}>
                  <td style={{ paddingTop: "20px" }}>{parse(`<div>${item.CeilingDesc1}</div>`)}</td>
                  <td style={{ paddingTop: "20px" }}>{item.CeilingTitle}</td>
                  <td style={{ paddingTop: "20px" }}>{parse(`<div>${item.CeilingDesc2}</div>`)}</td>
                  <td>
                    <IoEye
                      style={{ fontSize: "25px", color: "green" }}
                      onClick={() => {
                        handleShow6();
                        setView(item);
                      }}
                    />
                  </td>
                  <td>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <BiSolidEdit
                          className="text-success"
                          style={{ cursor: "pointer", fontSize: "20px" }}
                          onClick={() => {
                            handleShow4(item);
                            setData1(item);
                          }
                          }
                        />{" "}
                      </div>
                      <div>
                        <AiFillDelete
                          className="text-danger"
                          style={{ cursor: "pointer", fontSize: "20px" }}
                          onClick={() => {
                            handleShow5();
                            setData(item?._id);
                          }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      {/* Add Package modal */}
      <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
        <Modal.Header
          closeButton

        >
          <Modal.Title style={{ color: "black" }}>Add Ceiling Solution</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Description</label>
              <CKEditor editor={ClassicEditor}
                onChange={handleChange1}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Title</label>
              <input
                type="text"
                className="vi_0"
                placeholder="Enter Banner Title"
                onChange={(e) => setCeilingTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Description</label>
              <CKEditor editor={ClassicEditor}
                onChange={handleChange2}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setCeilingSliderImg1(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setCeilingSliderImg2(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setCeilingSliderImg3(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setCeilingSliderImg4(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setCeilingSliderImg5(e.target.files[0])}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <Button
              className="mx-2 modal-close-btn"
              variant=""
              onClick={handleClose3}
            >
              Close
            </Button>
            <Button
              className="mx-2 modal-add-btn"
              variant=""
              onClick={AddCeilingDetails}
            >
              Add
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Edit Package modal */}
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
        style={{ zIndex: "99999" }}
      >
        <Modal.Header
          closeButton
        >
          <Modal.Title style={{ color: "black" }}>Edit Ceiling Solution</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Description</label>
              <CKEditor editor={ClassicEditor}
                data={CeilingDesc1}
                onChange={handleChange1}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Title</label>
              <input
                type="text"
                className="vi_0"
                placeholder="Enter Banner Title"
                value={CeilingTitle}
                onChange={(e) => setCeilingTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Description</label>
              <CKEditor editor={ClassicEditor}
                data={CeilingDesc2}
                onChange={handleChange2}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setCeilingSliderImg1(e.target.files[0])}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setCeilingSliderImg2(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setCeilingSliderImg3(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setCeilingSliderImg4(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setCeilingSliderImg5(e.target.files[0])}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            className="modal-close-btn"
            onClick={handleClose4}
          >
            Close
          </Button>
          <Button variant="" className="modal-add-btn"
            onClick={EditCeiling}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delet modal  */}
      <Modal
        show={show5}
        onHide={handleClose5}
        backdrop="static"
        keyboard={false}
        style={{ zIndex: "99999" }}
      >
        <Modal.Header
          closeButton
        >
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <p className="fs-4" style={{ color: "red" }}>
                Are you sure?
                <br /> you want to delete this data?
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            className="modal-close-btn"
            onClick={handleClose5}
          >
            Close
          </Button>
          <Button
            variant=""
            className="modal-add-btn"
            onClick={DeleteCeiling}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>


      {/* Slider Image modal  */}
      <Modal
        show={show6}
        onHide={handleClose6}
        backdrop="static"
        keyboard={false}
        style={{ zIndex: "99999" }}
      >
        <Modal.Header
          closeButton
        >
          <Modal.Title style={{ color: "black" }}>Category Slider Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="row">
              <div className="col-md-6 mb-2">
                <Image
                  src={`https://roomyy.life/CeilingSolution/${View?.CeilingSliderImg1}`}
                  alt="pic"
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
              <div className="col-md-6 mb-2">
                <Image
                  src={`https://roomyy.life/CeilingSolution/${View?.CeilingSliderImg2}`}
                  alt="pic"
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
              <div className="col-md-6 mb-2">
                <Image
                  src={`https://roomyy.life/CeilingSolution/${View?.CeilingSliderImg3}`}
                  alt="pic"
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
              <div className="col-md-6 mb-2">
                <Image
                  src={`https://roomyy.life/CeilingSolution/${View?.CeilingSliderImg4}`}
                  alt="pic"
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
              <div className="col-md-6 mb-2">
                <Image
                  src={`https://roomyy.life/CeilingSolution/${View?.CeilingSliderImg5}`}
                  alt="pic"
                  style={{ width: "100%", height: "200px" }}
                />
              </div>

            </div>
          </Container>

        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            className="modal-close-btn"
            onClick={handleClose6}
          >
            Close
          </Button>

        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default AdminCeilingSolution