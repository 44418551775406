import React from "react";
import "../Styles/header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaRegUserCircle } from "react-icons/fa";
import { PiUserFill } from "react-icons/pi";


const Header = () => {

    return (
        <div
            className="headers"
            style={{
                position: "sticky",
                top: "0px",
                zIndex: "9999999",
                backgroundColor: "white",
            }}
        >
            {["xl"].map((expand) => (
                <Navbar
                    key={expand}
                    expand={expand}
                    style={{ padding: "0px", background: "white" }}
                >
                    <Container fluid style={{ padding: "5px 20px" }}>
                        <div className="d-flex gap-2">
                            <a href="/" className="tail-text">
                                <img src="../Assets/logo.png" alt="Logo" className="logo" />
                            </a>
                          
                        </div>
                        <Navbar.Toggle
                            aria-controls={`offcanvasNavbar-expand-${expand}`}
                        />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton></Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav
                                    className="justify-content-end flex-grow-1 pe-3"
                                    style={{ alignItems: "center" }}
                                >
                                     <Nav.Link href="/" className="tail-text">
                                       HOME
                                    </Nav.Link>
                                    <Nav.Link href="/aboutus" className="tail-text">
                                        ABOUT US
                                    </Nav.Link>
                                    <Nav.Link href="/renovation" className="tail-text">
                                        RENOVATION
                                    </Nav.Link>
                                    <Nav.Link href="/interior" className="tail-text">
                                        INTERIOR
                                    </Nav.Link>
                                    <Nav.Link href="/fabrication" className="tail-text">
                                        FABRICATION
                                    </Nav.Link>
                                    <Nav.Link href="/ceilingsolution" className="tail-text">
                                        CEILING SOLUTION
                                    </Nav.Link>
                                    <Nav.Link href="/gallery" className="tail-text">
                                        GALLERY
                                    </Nav.Link>
                                    <Nav.Link href="/contactus" className="tail-text">
                                        CONTACT US
                                    </Nav.Link>
                                    {/* <Nav.Link href="/login" className="tail-text">
                                       <FaRegUserCircle style={{fontSize:"20px"}}/>
                                    </Nav.Link> */}
                                </Nav>


{/* 
                                <div className="navbar-right-content">
                                    <li className="nav-item dropdown">
                                        <div style={{ color: "white" }}>
                                            <FaRegUserCircle
                                                style={{
                                                    borderRadius: "100%",
                                                    width: "30px",
                                                    height: "30px",
                                                }}
                                            />{" "}
                                            UserName                  
                                                  </div>
                                        <div className="dropdown-content">
                                            <a href="/profile">Profile</a>
                                            <p
                                                style={{
                                                    borderBottom: "1px solid lightgray",
                                                    margin: "5px",
                                                }}
                                            ></p>
                                            <a href="/orderdetails">Booking History</a>
                                            <p
                                                style={{
                                                    borderBottom: "1px solid lightgray",
                                                    margin: "5px",
                                                }}
                                            ></p>
                                            <a href="/">Logout</a>
                                        </div>
                                    </li>
                                    <Nav.Link
                                        href="/login"
                                        className="tail-text"
                                        style={{
                                            color: "#083a87 ",
                                            borderRadius: "12px",
                                            padding: "12px 8px",
                                            background: "white",
                                        }}
                                    >
                                        Login/Register
                                    </Nav.Link>
                                </div> */}
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </div>)
}

export default Header