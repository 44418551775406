import React, { useState, useEffect } from "react";
import { Container, Button, Card, Modal, Row } from "react-bootstrap";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Aos from "aos";
import { FaArrowTurnUp } from "react-icons/fa6";
import "../Styles/interior.css"
import axios from "axios";
import parse from 'html-react-parser';


const CeilingSolution = () => {

  const options1 = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  });


  // ==========Banner=========//
    //integrating get  method
    const [AddCeilingBanner, setAddCeilingBanner] = useState([]);
    const getAddCeilingBanner = async () => {
        try {
            let res = await axios.get(
                "https://roomyy.life/api/admin/getceilingbanner"
            );
            if (res.status === 200) {
                setAddCeilingBanner(res.data.getceilingbanner);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
      getAddCeilingBanner();
  }, []);

  // =========CEILONG=============//
    //integrating get  method
    const [AddCeiling, setAddCeiling] = useState([]);
    const getAddCeiling = async () => {
      try {
        let res = await axios.get(
          "https://roomyy.life/api/admin/getceiling"
        );
        if (res.status === 200) {
          setAddCeiling(res.data.getceiling);
        }
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getAddCeiling();
    }, []);

  return (
    <div>
       {AddCeilingBanner?.map((val, i) => {
        return (
          <div className="aboutbg-img'">
            <img src={`https://roomyy.life/CeilingBanner/${val?.CeilingBannerImage}`}
              alt=''
              className='about-img'
            />
            <div>
              <h2 className="headding1">
                {parse(`<div>${val?.CeilingBannerCaption}</div>`)}
              </h2>
            </div>

            <div className="fixed-icon">
              <div>
                <a href='https://wa.link/wvrxiz' target="_new">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    viewBox="0,0,256,256"
                    width="43px"
                    height="43px"
                  >
                    <g transform="translate(51.2,51.2) scale(0.6,0.6)">
                      <g
                        fill="green "
                        fill-rule="nonzero"
                        stroke="none"
                        stroke-width="1"
                        stroke-linecap="butt"
                        stroke-linejoin="miter"
                        stroke-miterlimit="10"
                        stroke-dasharray=""
                        stroke-dashoffset="0"
                        font-family="none"
                        font-weight="none"
                        font-size="none"
                        text-anchor="none"
                        style={{ mixBlendMode: "normal" }}
                      >
                        <g transform="scale(5.12,5.12)">
                          <path d="M25,2c-12.682,0 -23,10.318 -23,23c0,3.96 1.023,7.854 2.963,11.29l-2.926,10.44c-0.096,0.343 -0.003,0.711 0.245,0.966c0.191,0.197 0.451,0.304 0.718,0.304c0.08,0 0.161,-0.01 0.24,-0.029l10.896,-2.699c3.327,1.786 7.074,2.728 10.864,2.728c12.682,0 23,-10.318 23,-23c0,-12.682 -10.318,-23 -23,-23zM36.57,33.116c-0.492,1.362 -2.852,2.605 -3.986,2.772c-1.018,0.149 -2.306,0.213 -3.72,-0.231c-0.857,-0.27 -1.957,-0.628 -3.366,-1.229c-5.923,-2.526 -9.791,-8.415 -10.087,-8.804c-0.295,-0.389 -2.411,-3.161 -2.411,-6.03c0,-2.869 1.525,-4.28 2.067,-4.864c0.542,-0.584 1.181,-0.73 1.575,-0.73c0.394,0 0.787,0.005 1.132,0.021c0.363,0.018 0.85,-0.137 1.329,1.001c0.492,1.168 1.673,4.037 1.819,4.33c0.148,0.292 0.246,0.633 0.05,1.022c-0.196,0.389 -0.294,0.632 -0.59,0.973c-0.296,0.341 -0.62,0.76 -0.886,1.022c-0.296,0.291 -0.603,0.606 -0.259,1.19c0.344,0.584 1.529,2.493 3.285,4.039c2.255,1.986 4.158,2.602 4.748,2.894c0.59,0.292 0.935,0.243 1.279,-0.146c0.344,-0.39 1.476,-1.703 1.869,-2.286c0.393,-0.583 0.787,-0.487 1.329,-0.292c0.542,0.194 3.445,1.604 4.035,1.896c0.59,0.292 0.984,0.438 1.132,0.681c0.148,0.242 0.148,1.41 -0.344,2.771z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        );
      })}

      <Container>
      {AddCeiling?.map((val, i) => {
        return (
        <div className='row mt-3 mb-3'>

          <div className='col-md-7' data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="3000">
            <p>
            {parse(`<div>${val?.CeilingDesc1}</div>`)}
            </p>
            <h4>
            {parse(`<div>${val?.CeilingTitle}</div>`)}
            </h4>


            <p>
            {parse(`<div>${val?.CeilingDesc2}</div>`)}
            </p>
          </div>
          <div className='col-md-5'>
            <OwlCarousel
              loop
              margin={5}
              nav
              {...options1}
            >
              <div class="item">
                <div class="card" style={{}}>
                  <div class="image-box">
                    <img
                       src={`https://roomyy.life/CeilingSolution/${val?.CeilingSliderImg1}`} alt=""
                      className="about-slider-img"
                    />
                  </div>

                </div>
              </div>
              <div class="item">
                <div class="card" style={{}}>
                  <div class="image-box">
                  <img
                       src={`https://roomyy.life/CeilingSolution/${val?.CeilingSliderImg2}`} alt=""
                      className="about-slider-img"
                    />
                  </div>

                </div>
              </div>

              <div class="item">
                <div class="card" >
                  <div class="image-box">
                  <img
                       src={`https://roomyy.life/CeilingSolution/${val?.CeilingSliderImg3}`} alt=""
                      className="about-slider-img"
                    />
                  </div>

                </div>
              </div>

              <div class="item">
                <div class="card">
                  <div class="image-box">
                  <img
                       src={`https://roomyy.life/CeilingSolution/${val?.CeilingSliderImg4}`} alt=""
                      className="about-slider-img"
                    />
                  </div>

                </div>
              </div>

              <div class="item">
                <div class="card">
                  <div class="image-box">
                  <img
                       src={`https://roomyy.life/CeilingSolution/${val?.CeilingSliderImg5}`} alt=""
                      className="about-slider-img"
                    />
                  </div>

                </div>
              </div>
            </OwlCarousel>
          </div>

          {/* <p className='mt-3' data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="3000">
            <p>  Roof Installation: Whether you're building a new home or replacing an old roof, our roof installation services are tailored to meet your needs. We work with a variety of roofing materials, including asphalt shingles, metal roofing, and flat roofing systems, to ensure that your roof is not only durable and long-lasting but also complements the style of your property.
            </p>
            <p>Roof Repair: From minor leaks to major storm damage, our roof repair services can address a wide range of issues to restore the integrity of your roof. Our experienced technicians will conduct a thorough inspection to identify any problems and provide prompt and effective repairs to prevent further damage and prolong the life of your roof.
            </p>
            <p>Maintenance and Inspections: Regular maintenance and inspections are essential to ensuring the longevity and performance of your roof. Our maintenance programs include routine inspections, cleaning, and minor repairs to keep your roof in optimal condition year-round. With proactive maintenance, you can avoid costly repairs and extend the life of your roof.
            </p>
          </p> */}

        </div>
          );
        })}
  
      </Container>
      {/* 
      <Container>
        <div>
          <div>
            <h1 className='content-title mt-5 mb-3'>
              Meet the Minds <br />Behind the Projects</h1>
            <h2>The Faces Behind Our Success, Meet Our Interior Design Experts.</h2>
          </div>
          <div>
            <div className="about-display-container mt-3">
              <div id="cards_landscape_wrap-2ss">
                <div className="service-cards">


                  <div class="card-flyer mt-5">
                    <div class="text-box">
                      <div class="image-box">
                        <img
                          src="../Assets/user1.jpg"
                          alt=""
                          className="homepage-service-img"
                          style={{ height: "390px" }}
                        />
                      </div>

                      <div class="overlay text-container">
                        <h6 className=''>Alisson Taylor</h6>
                        <h5>Team Manager</h5>

                      </div>
                    </div>
                  </div>

                  <div class="card-flyer ">
                    <div class="text-box">
                      <div class="image-box">
                        <img
                          src="../Assets/user2.jpg"
                          alt=""
                          className="homepage-service-img"
                          style={{ height: "390px" }}

                        />
                      </div>

                      <div class="overlay text-container ">
                        <h6>Roger Craig</h6>
                        <h5>CEO</h5>
                      </div>
                    </div>
                  </div>

                  <div class="card-flyer mt-5">
                    <div class="text-box">
                      <div class="image-box">
                        <img
                          src="../Assets/user3.jpg"
                          alt=""
                          className="homepage-service-img"
                          style={{ height: "390px" }}

                        />
                      </div>

                      <div class=" overlay text-container ">
                        <h6>Allan Cooper</h6>
                        <h5>CTO</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container>
        <div className='d-flex justify-content-space-between'>
          <div>
            <h1 className='content-title mt-5 mb-3'>
              Get in Touch</h1>
            <h2>Building Relationships, One Conversation at a Time</h2>
          </div>
          <div style={{ margin: "auto" }}>
            <div className='d-flex'>
              <a href='/contactus'>
                <p style={{ color: "black", fontSize: "20px" }}>Contact us <FaArrowTurnUp /></p>
              </a>
            </div>

          </div>
        </div>
      </Container> */}
    </div>
  )
}

export default CeilingSolution