import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from "react-router-dom";
import {
  LuActivity,
  LuBookMarked,
  LuFileQuestion,
  LuIndianRupee,
  LuListOrdered,
  LuLogOut,
  LuPackageX,
  LuUserCog,
} from "react-icons/lu";
import { FaQuestion, FaWeightHanging } from "react-icons/fa";
import { IoMdContact } from "react-icons/io";
import { FaShop, FaRegIdCard, FaCircleUser } from "react-icons/fa6";
import { GiFlatPlatform } from "react-icons/gi";
import { RiSecurePaymentLine } from "react-icons/ri";
import { AiOutlineSnippets } from "react-icons/ai";
import { GrGallery } from "react-icons/gr";
import { IoPeopleOutline, IoNewspaperOutline } from "react-icons/io5";
import { PiExamFill, PiHandshakeLight } from "react-icons/pi";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineTipsAndUpdates,
  MdOutlineKeyboardArrowUp,
  MdOutlineSupportAgent,
  MdOutlineAddComment,
  MdEventAvailable,
  MdSubject,
  MdOutlineKeyboardArrowLeft,
  MdOutlineReviews,
} from "react-icons/md";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { LuAlignHorizontalJustifyStart } from "react-icons/lu";
import { IoEarth } from "react-icons/io5";
import "../Admin/Admin.css";
import Navbar from "react-bootstrap/Navbar";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { MdLooksOne } from "react-icons/md";
import { PiNumberSquareTwoFill } from "react-icons/pi";
import { PiNumberSquareThreeFill } from "react-icons/pi";
import { PiNumberSquareFourFill } from "react-icons/pi";
import { PiNumberSquareFiveFill } from "react-icons/pi";
import { TiTick } from "react-icons/ti";
import { FaArrowsLeftRightToLine } from "react-icons/fa6";
import { FaPersonCircleQuestion } from "react-icons/fa6";
import { AiFillSetting, AiOutlineHome } from "react-icons/ai";
import { BsFillTelephoneFill, BsQuestionLg } from "react-icons/bs";

const Side = () => {

  const [acc, setacc] = useState(true);
  const [acc1, setacc1] = useState(false);
  const [acc2, setacc2] = useState(false);
  const [acc3, setacc3] = useState(false);
  const [acc4, setacc4] = useState(false);
  const [acc5, setacc5] = useState(false);
  const [acc6, setacc6] = useState(false);
  const [acc7, setacc7] = useState(false);
  const [acc8, setacc8] = useState(false);
  const [acc9, setacc9] = useState(false);
  const [acc10, setacc10] = useState(false);
  const [acc11, setacc11] = useState(false);
  const [acc12, setacc12] = useState(false);
  const [acc13, setacc13] = useState(false);
  const [acc14, setacc14] = useState(false);
  const [acc15, setacc15] = useState(false);
  const [acc16, setacc16] = useState(false);
  const [acc17, setacc17] = useState(false);
  const [acc18, setacc18] = useState(false);
  const [acc19, setacc19] = useState(false);
  const [acc20, setacc20] = useState(false);
  const [acc21, setacc21] = useState(false);

  const [Home, setHome] = useState(false);
  const [About, setAbout] = useState(false);
  const [Renovation, setRenovation] = useState(false);
  const [Interior, setInterior] = useState(false);
  const [Fabrication, setFabrication] = useState(false);
  const [Celing, setCeling] = useState(false);



  // Responsive sidebar
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  return (
    <div>
      <Navbar expand="lg" className=" p-0">
        <button
          class="custom-toggler navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarsExample09"
          aria-controls="navbarsExample09"
          aria-expanded={!isNavCollapsed ? true : false}
          aria-label="Toggle navigation"
          onClick={handleNavCollapse}
          style={{ margin: "10px" }}
        >
          <span>
            <GiHamburgerMenu style={{ color: "white" }} />
          </span>
        </button>
        <div
          class={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
          id="navbarsExample09"
        >
          <div className="si09">
            <div style={{ width: "100%", justifyContent: "space-between" }}>
              <div className="lo-ad" style={{ background: "white", borderBottom: "1px solid white" }}>
                <div className="">
                  <a href="/" className="tail-text">
                    <img src="../Assets/logo.png" alt="Logo" className="admin-logo-img" style={{ width: "100%" }} />
                  </a>

                </div>
              </div>
              <div className="sidebar-close-icon" onClick={handleNavCollapse}>
                <AiOutlineClose />
              </div>
            </div>
            <ul>
              {/* <Link to="/dashboard" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <MdOutlineSupportAgent style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Dashboard</span>
                </li>
              </Link> */}
              {/* Home Dropdowns  */}
              <Link to="">
                <li
                  className={`a-ele ${acc ? "active-0" : "null"}`}
                  onClick={() => {
                    setHome(!Home);
                  }}
                >
                  <span>
                    <AiOutlineHome style={{ fontSize: "20px" }} />
                  </span>{" "}
                  <span>Home </span>{" "}
                  {Home ? (
                    <>
                      {" "}
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowUp />
                      </span>
                    </>
                  ) : (
                    <>
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowDown />
                      </span>
                    </>
                  )}
                </li>
              </Link>
              <Link to="">
                {Home ? (
                  <>
                    <div className="webmanagement">
                      <Link to="/admin_homeslider">
                        <li
                          className={`a-ele ${acc ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(true);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);

                          }}
                        >
                          <span>
                            <i
                              class="fa-solid fa-list-ul"
                              style={{ fontSize: "23px" }}
                            ></i>
                          </span>
                          <span className="ms-2">Slider</span>
                        </li>
                      </Link>
                      <Link to="/admin_homeservices">
                        <li
                          className={`a-ele ${acc1 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(true);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);
                          }}
                        >
                          <span>
                            {" "}
                            <i class="fa fa-arrows" style={{ fontSize: "18px" }}></i>
                          </span>
                          <span className="ms-2">Services </span>{" "}
                        </li>
                      </Link>
                      <Link to="/admin_homephilosophy">
                        <li
                          className={`a-ele ${acc2 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(true);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);
                          }}
                        >
                          <span>
                            {" "}
                            <BsQuestionLg style={{ fontSize: "20px" }} />
                          </span>
                          <span className="ms-2">Philosophy</span>{" "}
                        </li>
                      </Link>
                      <Link to="/admin_homecategory">
                        <li
                          className={`a-ele ${acc3 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(true);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);
                          }}
                        >
                          <span>
                            {" "}
                            <i class="fa fa-arrows" style={{ fontSize: "18px" }}></i>
                          </span>
                          <span className="ms-2">Categories </span>{" "}
                        </li>
                      </Link>
                      <Link to="/admin_homewhychooseus">
                        <li
                          className={`a-ele ${acc4 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(true);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);
                          }}
                        >
                          <span>
                            {" "}
                            <i class="fa fa-arrows" style={{ fontSize: "18px" }}></i>
                          </span>
                          <span className="ms-2">Why Choose us? </span>{" "}
                        </li>
                      </Link>

                    </div>
                  </>
                ) : (
                  ""
                )}
              </Link>

              {/* About Dropdowns  */}
              <Link to="">
                <li
                  className={`a-ele ${acc5 ? "active-0" : "null"}`}
                  onClick={() => {
                    setAbout(!About);
                  }}
                >
                  <span>
                    <AiOutlineHome style={{ fontSize: "20px" }} />
                  </span>{" "}
                  <span>About Us </span>{" "}
                  {About ? (
                    <>
                      {" "}
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowUp />
                      </span>
                    </>
                  ) : (
                    <>
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowDown />
                      </span>
                    </>
                  )}
                </li>
              </Link>
              <Link to="">
                {About ? (
                  <>
                    <div className="webmanagement">
                      <Link to="/admin_aboutbanner">
                        <li
                          className={`a-ele ${acc5 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(true);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);
                          }}
                        >
                          <span>
                            <i
                              class="fa-solid fa-list-ul"
                              style={{ fontSize: "23px" }}
                            ></i>
                          </span>
                          <span className="ms-2">About Banner</span>
                        </li>
                      </Link>
                      <Link to="/admin_aboutus">
                        <li
                          className={`a-ele ${acc6 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(true);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);
                          }}
                        >
                          <span>
                            {" "}
                            <i class="fa fa-arrows" style={{ fontSize: "18px" }}></i>
                          </span>
                          <span className="ms-2">About us </span>{" "}
                        </li>
                      </Link>
                      <Link to="/admin_aboutourservices">
                        <li
                          className={`a-ele ${acc7 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(true);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);

                          }}
                        >
                          <span>
                            {" "}
                            <BsQuestionLg style={{ fontSize: "20px" }} />
                          </span>
                          <span className="ms-2">Our Services</span>{" "}
                        </li>
                      </Link>
                      <Link to="/admin_aboutourpassion">
                        <li
                          className={`a-ele ${acc8 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(true);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);
                          }}
                        >
                          <span>
                            {" "}
                            <i class="fa fa-arrows" style={{ fontSize: "18px" }}></i>
                          </span>
                          <span className="ms-2">Our Passion </span>{" "}
                        </li>
                      </Link>
                      <Link to="/admin_aboutshowcases">
                        <li
                          className={`a-ele ${acc9 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(true);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);

                          }}
                        >
                          <span>
                            {" "}
                            <i class="fa fa-arrows" style={{ fontSize: "18px" }}></i>
                          </span>
                          <span className="ms-2">Showcases</span>{" "}
                        </li>
                      </Link>

                    </div>
                  </>
                ) : (
                  ""
                )}
              </Link>



              {/* ===========================Renovation */}

              <Link to="">
                <li
                  className={`a-ele ${acc10 ? "active-0" : "null"}`}
                  onClick={() => {
                    setRenovation(!Renovation);
                  }}
                >
                  <span>
                    <AiOutlineHome style={{ fontSize: "20px" }} />
                  </span>{" "}
                  <span>Renovation</span>{" "}
                  {Renovation ? (
                    <>
                      {" "}
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowUp />
                      </span>
                    </>
                  ) : (
                    <>
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowDown />
                      </span>
                    </>
                  )}
                </li>
              </Link>

              <Link to="">
                {Renovation ? (
                  <>
                    <div className="webmanagement">
                      <Link to="/renovationbanner">
                        <li
                          className={`a-ele ${acc10 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(true);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);
                          }}
                        >
                          <span>
                            <i
                              class="fa-solid fa-list-ul"
                              style={{ fontSize: "23px" }}
                            ></i>
                          </span>
                          <span className="ms-2">Renovation Banner</span>
                        </li>
                      </Link>
                      <Link to="/admin_renovation">
                        <li
                          className={`a-ele ${acc11 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(true);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);
                          }}
                        >
                          <span>
                            {" "}
                            <i class="fa fa-arrows" style={{ fontSize: "18px" }}></i>
                          </span>
                          <span className="ms-2">Renovation</span>{" "}
                        </li>
                      </Link>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Link>


              {/* //////////////////////////////////INTERIOR */}

              <Link to="">
                <li
                  className={`a-ele ${acc12 ? "active-0" : "null"}`}
                  onClick={() => {
                    setInterior(!Interior);
                  }}
                >
                  <span>
                    <AiOutlineHome style={{ fontSize: "20px" }} />
                  </span>{" "}
                  <span>Interior</span>{" "}
                  {Interior ? (
                    <>
                      {" "}
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowUp />
                      </span>
                    </>
                  ) : (
                    <>
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowDown />
                      </span>
                    </>
                  )}
                </li>
              </Link>

              <Link to="">
                {Interior ? (
                  <>
                    <div className="webmanagement">
                      <Link to="/admin_interiorbanner">
                        <li
                          className={`a-ele ${acc12 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(true);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);
                          }}
                        >
                          <span>
                            <i
                              class="fa-solid fa-list-ul"
                              style={{ fontSize: "23px" }}
                            ></i>
                          </span>
                          <span className="ms-2">Interior Banner</span>
                        </li>
                      </Link>
                      <Link to="/admin_interior">
                        <li
                          className={`a-ele ${acc13 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(true);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);
                          }}
                        >
                          <span>
                            {" "}
                            <i class="fa fa-arrows" style={{ fontSize: "18px" }}></i>
                          </span>
                          <span className="ms-2">Interior</span>{" "}
                        </li>
                      </Link>
                      <Link to="/admin_findbetterinterior">
                        <li
                          className={`a-ele ${acc14 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(true);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);
                          }}
                        >
                          <span>
                            {" "}
                            <i class="fa fa-arrows" style={{ fontSize: "18px" }}></i>
                          </span>
                          <span className="ms-2">Find Better Interior</span>{" "}
                        </li>
                      </Link>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Link>


              {/* //////////////////////////////////FABRICATION */}

              <Link to="">
                <li
                  className={`a-ele ${acc15 ? "active-0" : "null"}`}
                  onClick={() => {
                    setFabrication(!Fabrication);
                  }}
                >
                  <span>
                    <AiOutlineHome style={{ fontSize: "20px" }} />
                  </span>{" "}
                  <span>Fabrication</span>{" "}
                  {Fabrication ? (
                    <>
                      {" "}
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowUp />
                      </span>
                    </>
                  ) : (
                    <>
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowDown />
                      </span>
                    </>
                  )}
                </li>
              </Link>

              <Link to="">
                {Fabrication ? (
                  <>
                    <div className="webmanagement">
                      <Link to="/admin_fabricationbanner">
                        <li
                          className={`a-ele ${acc15 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(true);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);
                          }}
                        >
                          <span>
                            <i
                              class="fa-solid fa-list-ul"
                              style={{ fontSize: "23px" }}
                            ></i>
                          </span>
                          <span className="ms-2">Fabrication Banner</span>
                        </li>
                      </Link>
                      <Link to="/admin_fabrication">
                        <li
                          className={`a-ele ${acc16 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(true);
                            setacc17(false);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);
                          }}
                        >
                          <span>
                            {" "}
                            <i class="fa fa-arrows" style={{ fontSize: "18px" }}></i>
                          </span>
                          <span className="ms-2">Fabrication</span>{" "}
                        </li>
                      </Link>
                      <Link to="/admin_fabricationtypes">
                        <li
                          className={`a-ele ${acc17 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(true);
                            setacc18(false);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);
                          }}
                        >
                          <span>
                            {" "}
                            <i class="fa fa-arrows" style={{ fontSize: "18px" }}></i>
                          </span>
                          <span className="ms-2">Fabrication Types</span>{" "}
                        </li>
                      </Link>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Link>


              {/* //////////////////////////////////CEILING SOLUTION */}

              <Link to="">
                <li
                  className={`a-ele ${acc18 ? "active-0" : "null"}`}
                  onClick={() => {
                    setCeling(!Celing);
                  }}
                >
                  <span>
                    <AiOutlineHome style={{ fontSize: "20px" }} />
                  </span>{" "}
                  <span>Ceiling Solution</span>{" "}
                  {Celing ? (
                    <>
                      {" "}
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowUp />
                      </span>
                    </>
                  ) : (
                    <>
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowDown />
                      </span>
                    </>
                  )}
                </li>
              </Link>

              <Link to="">
                {Celing ? (
                  <>
                    <div className="webmanagement">
                      <Link to="/admin_ceilingbanner">
                        <li
                          className={`a-ele ${acc18 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(true);
                            setacc19(false);
                            setacc20(false);
                            setacc21(false);
                          }}
                        >
                          <span>
                            <i
                              class="fa-solid fa-list-ul"
                              style={{ fontSize: "23px" }}
                            ></i>
                          </span>
                          <span className="ms-2">Ceiling Banner</span>
                        </li>
                      </Link>
                      <Link to="/admin_ceilingsolution">
                        <li
                          className={`a-ele ${acc19 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                            setacc9(false);
                            setacc10(false);
                            setacc11(false);
                            setacc12(false);
                            setacc13(false);
                            setacc14(false);
                            setacc15(false);
                            setacc16(false);
                            setacc17(false);
                            setacc18(false);
                            setacc19(true);
                            setacc20(false);
                            setacc21(false);
                          }}
                        >
                          <span>
                            {" "}
                            <i class="fa fa-arrows" style={{ fontSize: "18px" }}></i>
                          </span>
                          <span className="ms-2">Ceiling Solution</span>{" "}
                        </li>
                      </Link>

                    </div>
                  </>
                ) : (
                  ""
                )}
              </Link>



            


              <Link to="/admin_gallery" onClick={handleNavCollapse}>
                <li
                  className="a-ele "
                >
                  <span>
                    <LuAlignHorizontalJustifyStart style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Gallery </span>
                </li>
              </Link>

              <Link to="/admin_contactus" onClick={handleNavCollapse}>
                <li
                  className="a-ele "
                >
                  <span>
                    <MdSubject style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Contact Us</span>
                </li>
              </Link>

              <Link to="/generalenquiry" onClick={handleNavCollapse}>
                <li
                  className="a-ele "                >
                  <span>
                    <FaWeightHanging style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Enquiries</span>
                </li>
              </Link>

              {/* <Link to="/serviceenquiry" onClick={handleNavCollapse}>
                <li
                  className="a-ele "                >
                  <span>
                    <FaWeightHanging style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Service Enquiries</span>
                </li>
              </Link> */}

              {/* <Link to="/bookinglist" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <FaWeightHanging style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Booking Service List</span>
                </li>
              </Link> */}

              {/* <Link to="/userlist" onClick={handleNavCollapse}>
                <li
                  className="a-ele "
                >
                  <span>
                    <PiExamFill style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">User List</span>
                </li>
              </Link> */}
            </ul>
          </div>
        </div>
      </Navbar>
    </div>
  );
};

export default Side;
