import React, { useState, useEffect } from 'react'
import "../Styles/about.css"
import { Container, Button, Card, Modal, Row } from "react-bootstrap";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FaArrowTurnUp } from "react-icons/fa6";
import Aos from "aos";
import parse from 'html-react-parser'
import axios from "axios";

const About = () => {


    useEffect(() => {
        Aos.init();
        window.scroll(0, 0);
    });

    const options1 = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
    };

    // ==========ABOUT BANNER=========//
    //integrating get  method
    const [AddAboutBanner, setAddAboutBanner] = useState([]);
    const getAddAboutBanner = async () => {
        try {
            let res = await axios.get(
                "https://roomyy.life/api/admin/getaboutbanner"
            );
            if (res.status === 200) {
                setAddAboutBanner(res.data.getaboutbanner);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getAddAboutBanner();
    }, []);


    // =========ABOUT US========//
    //integrating get  method
    const [AddAboutus, setAddAboutus] = useState([]);
    const getAddAboutus = async () => {
        try {
            let res = await axios.get(
                "https://roomyy.life/api/admin/getaboutus"
            );
            if (res.status === 200) {
                setAddAboutus(res.data.getaboutus);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getAddAboutus();
    }, []);


    // ======OUR SERVICES========//
    //integrating get  method
    const [AddAboutService, setAddAboutService] = useState([]);
    const getAddAboutService = async () => {
        try {
            let res = await axios.get(
                "https://roomyy.life/api/admin/getaboutservice"
            );
            if (res.status === 200) {
                setAddAboutService(res.data.getaboutservice);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getAddAboutService();
    }, []);


    // =========OUR PASSION==========//
    //integrating get  method
    const [AddAboutPassion, setAddAboutPassion] = useState([]);
    const getAddAboutPassion = async () => {
        try {
            let res = await axios.get(
                "https://roomyy.life/api/admin/getaboutpassion"
            );
            if (res.status === 200) {
                setAddAboutPassion(res.data.getaboutpassion);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getAddAboutPassion();
    }, []);

    // ========SHOWCASE======//
    //integrating get  method
    const [AddAboutShowcase, setAddAboutShowcase] = useState([]);
    const getAddAboutShowcase = async () => {
        try {
            let res = await axios.get(
                "https://roomyy.life/api/admin/getaboutshowcase"
            );
            if (res.status === 200) {
                setAddAboutShowcase(res.data.getaboutshowcase);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getAddAboutShowcase();
    }, []);


    return (
        <div>
            {AddAboutBanner?.map((val, i) => {
                return (
                    <div className="aboutbg-img'">
                        <img src={`https://roomyy.life/AboutBanner/${val?.AboutBannerImage}`}
                            alt=''
                            className='about-img'
                        />
                        <div>
                            <h2 className="headding">
                                {parse(`<div>${val?.AboutBannerCaption}</div>`)}
                            </h2>
                        </div>

                        <div className="fixed-icon">
                            <div>
                                <a href='https://wa.link/wvrxiz' target="_new">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xlink="http://www.w3.org/1999/xlink"
                                        viewBox="0,0,256,256"
                                        width="43px"
                                        height="43px"
                                    >
                                        <g transform="translate(51.2,51.2) scale(0.6,0.6)">
                                            <g
                                                fill="green "
                                                fill-rule="nonzero"
                                                stroke="none"
                                                stroke-width="1"
                                                stroke-linecap="butt"
                                                stroke-linejoin="miter"
                                                stroke-miterlimit="10"
                                                stroke-dasharray=""
                                                stroke-dashoffset="0"
                                                font-family="none"
                                                font-weight="none"
                                                font-size="none"
                                                text-anchor="none"
                                                style={{ mixBlendMode: "normal" }}
                                            >
                                                <g transform="scale(5.12,5.12)">
                                                    <path d="M25,2c-12.682,0 -23,10.318 -23,23c0,3.96 1.023,7.854 2.963,11.29l-2.926,10.44c-0.096,0.343 -0.003,0.711 0.245,0.966c0.191,0.197 0.451,0.304 0.718,0.304c0.08,0 0.161,-0.01 0.24,-0.029l10.896,-2.699c3.327,1.786 7.074,2.728 10.864,2.728c12.682,0 23,-10.318 23,-23c0,-12.682 -10.318,-23 -23,-23zM36.57,33.116c-0.492,1.362 -2.852,2.605 -3.986,2.772c-1.018,0.149 -2.306,0.213 -3.72,-0.231c-0.857,-0.27 -1.957,-0.628 -3.366,-1.229c-5.923,-2.526 -9.791,-8.415 -10.087,-8.804c-0.295,-0.389 -2.411,-3.161 -2.411,-6.03c0,-2.869 1.525,-4.28 2.067,-4.864c0.542,-0.584 1.181,-0.73 1.575,-0.73c0.394,0 0.787,0.005 1.132,0.021c0.363,0.018 0.85,-0.137 1.329,1.001c0.492,1.168 1.673,4.037 1.819,4.33c0.148,0.292 0.246,0.633 0.05,1.022c-0.196,0.389 -0.294,0.632 -0.59,0.973c-0.296,0.341 -0.62,0.76 -0.886,1.022c-0.296,0.291 -0.603,0.606 -0.259,1.19c0.344,0.584 1.529,2.493 3.285,4.039c2.255,1.986 4.158,2.602 4.748,2.894c0.59,0.292 0.935,0.243 1.279,-0.146c0.344,-0.39 1.476,-1.703 1.869,-2.286c0.393,-0.583 0.787,-0.487 1.329,-0.292c0.542,0.194 3.445,1.604 4.035,1.896c0.59,0.292 0.984,0.438 1.132,0.681c0.148,0.242 0.148,1.41 -0.344,2.771z"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                );
            })}
            <Container className='mt-2 mb-2'>
                <div>

                    {AddAboutus?.map((val, i) => {
                        return (
                            <div className='row m-0'>
                                <div className='col-md-6'>
                                    <div>
                                        <h1 className='content-title' data-aos="zoom-in"
                                            data-aos-delay="50"
                                            data-aos-duration="3000">
                                            {parse(`<div>${val?.AboutusTitle}</div>`)}
                                        </h1>
                                        <h3>
                                            {parse(`<div>${val?.AboutusTagline}</div>`)}
                                        </h3>
                                    </div>
                                </div>

                                <div className='col-md-5'>
                                    <Container >
                                        <OwlCarousel data-aos="fade-left"
                                            data-aos-delay="50"
                                            data-aos-duration="3000"
                                            className="owl-theme"
                                            loop
                                            margin={5}
                                            nav
                                            {...options1}
                                        >

                                            {AddAboutus?.map((val, i) => {
                                                return (
                                                    <div class="item">
                                                        <div class="card" style={{}}>
                                                            <div class="image-box">
                                                                <img
                                                                    src={`https://roomyy.life/Aboutus/${val?.AboutSliderImage1}`}
                                                                    alt=""
                                                                    className="about-slider-img"
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            {AddAboutus?.map((val, i) => {
                                                return (
                                                    <div class="item">
                                                        <div class="card" style={{}}>
                                                            <div class="image-box">
                                                                <img
                                                                    src={`https://roomyy.life/Aboutus/${val?.AboutSliderImage2}`}
                                                                    alt=""
                                                                    className="about-slider-img"
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>
                                                );
                                            })}

                                            {AddAboutus?.map((val, i) => {
                                                return (
                                                    <div class="item">
                                                        <div class="card" style={{}}>
                                                            <div class="image-box">
                                                                <img
                                                                    src={`https://roomyy.life/Aboutus/${val?.AboutSliderImage3}`}
                                                                    alt=""
                                                                    className="about-slider-img"
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>
                                                );
                                            })}

                                            {AddAboutus?.map((val, i) => {
                                                return (
                                                    <div class="item">
                                                        <div class="card" style={{}}>
                                                            <div class="image-box">
                                                                <img
                                                                    src={`https://roomyy.life/Aboutus/${val?.AboutSliderImage4}`}
                                                                    alt=""
                                                                    className="about-slider-img"
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>
                                                );
                                            })}

                                            {AddAboutus?.map((val, i) => {
                                                return (
                                                    <div class="item">
                                                        <div class="card" style={{}}>
                                                            <div class="image-box">
                                                                <img
                                                                    src={`https://roomyy.life/Aboutus/${val?.AboutSliderImage5}`}
                                                                    alt=""
                                                                    className="about-slider-img"
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>
                                                );
                                            })}

                                            {AddAboutus?.map((val, i) => {
                                                return (
                                                    <div class="item">
                                                        <div class="card" style={{}}>
                                                            <div class="image-box">
                                                                <img
                                                                    src={`https://roomyy.life/Aboutus/${val?.AboutSliderImage6}`}
                                                                    alt=""
                                                                    className="about-slider-img"
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>
                                                );
                                            })}

                                        </OwlCarousel>
                                    </Container>
                                </div>
                            </div>
                        );
                    })}

                    {AddAboutus?.map((val, i) => {
                        return (
                            <p className='aboout-content mt-3 mb-3' data-aos="fade-up"
                                data-aos-delay="50"
                                data-aos-duration="3000" style={{ textAlign: "justify" }}>
                                {parse(`<div>${val?.AboutusDesc}</div>`)}                                </p>
                        );
                    })}
                </div>

            </Container>

            <Container>
                <div className='row mt-5'>
                    <div className=''>
                        {AddAboutService?.map((val, i) => {
                            return (
                                <div className='card-contents' data-aos="fade-up"
                                    data-aos-delay="50"
                                    data-aos-duration="3000">
                                    <h2>
                                        {parse(`<div>${val?.AboutServiceHeading}</div>`)}
                                    </h2>
                                </div>
                            );
                        })}
                    </div>

                </div>

                {AddAboutService?.map((val, i) => {
                    return (
                        <div className='row mt-5'>

                            <div className='col-md-4' data-aos="fade-up"
                                data-aos-delay="50"
                                data-aos-duration="3000">
                                <div className='card-contents'>
                                    <h5>
                                        {parse(`<div>${val?.AboutServiceTitle}</div>`)}
                                    </h5>
                                    <p style={{ color: "gray", lineHeight: "30px", textAlign: "justify" }}>
                                        {parse(`<div>${val?.AboutServiceDesc}</div>`)}
                                    </p>
                                </div>
                            </div>

                            <div className='col-md-4' data-aos="fade-down"
                                data-aos-delay="50"
                                data-aos-duration="3000">
                                <img src={`https://roomyy.life/AboutService/${val?.AboutServiceImg}`}
                                    alt='' className='card-img' />
                            </div>

                            <div className='col-md-4' data-aos="fade-up"
                                data-aos-delay="50"
                                data-aos-duration="3000">
                                <div className='card-contents'>
                                    <h5>
                                        {parse(`<div>${val?.AboutServiceTitle1}</div>`)}
                                    </h5>
                                    <p style={{ color: "gray", lineHeight: "30px", textAlign: "justify" }}>
                                        {parse(`<div>${val?.AboutServiceDesc1}</div>`)}
                                    </p>
                                </div>
                            </div>

                            <div class="horizontal-line mt-3 mb-3">
                                <div class="dots">
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                </div>
                            </div>
                        </div>
                    );
                })}

            </Container>

            <Container>
                <div >
                    <div>
                        {AddAboutPassion?.map((val, i) => {
                            return (
                                <h3 className='mt-5 mb-2'>
                                    {parse(`<div>${val?.AboutPassionHeading}</div>`)}
                                </h3>
                            );
                        })}
                    </div>

                    {AddAboutPassion?.map((val, i) => {
                        return (
                            <div className='row'>
                                <div className='col-md-6' data-aos="fade-up"
                                    data-aos-delay="50"
                                    data-aos-duration="3000">
                                    <img src={`https://roomyy.life/AboutPassion/${val?.AboutPassionImg}`}
                                        alt='' className='imgsss' />
                                </div>
                                <div className='col-md-6'>
                                    <p style={{ fontSize: "20px", padding: "10px 0px", textAlign: "justify" }}>
                                        {parse(`<div>${val?.AboutPassionDesc}</div>`)}
                                    </p>
                                </div>

                            </div>
                        );
                    })}

                </div>
            </Container>

            <Container className='mb-3'>
                {AddAboutShowcase?.map((val, i) => {
                    return (
                        <div>
                            <div>
                                <h1 className='content-title1 mt-4'>
                                    {parse(`<div>${val?.AboutShowcaseTitle}</div>`)}
                                </h1>
                                <hr></hr>
                                <h3>
                                    {parse(`<div>${val?.AboutShowcaseTagline}</div>`)}
                                </h3>
                            </div>

                            <div className="about-display-container mt-3">
                                <div id="cards_landscape_wrap-2">
                                    <div className="service-cards">
                                        <div class="card-flyer mb-2">
                                            <div class="text-box">
                                                <div class="image-box">
                                                    <img src={`https://roomyy.life/AboutShowcases/${val?.AboutShowcaseImg1}`}
                                                        alt=""
                                                        className="homepage-service-img"
                                                    />
                                                </div>

                                            </div>
                                        </div>

                                        <div class="card-flyer mb-2">
                                            <div class="text-box">
                                                <div class="image-box">
                                                <img src={`https://roomyy.life/AboutShowcases/${val?.AboutShowcaseImg2}`}
                                                        alt=""
                                                        className="homepage-service-img"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card-flyer mb-2">
                                            <div class="text-box">
                                                <div class="image-box">
                                                <img src={`https://roomyy.life/AboutShowcases/${val?.AboutShowcaseImg3}`}
                                                        alt=""
                                                        className="homepage-service-img"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="about-display-container mt-3">
                                <div id="cards_landscape_wrap-2">
                                    <div className="service-cards">
                                        <div class="card-flyer mb-2">
                                            <div class="text-box">
                                                <div class="image-box">
                                                <img src={`https://roomyy.life/AboutShowcases/${val?.AboutShowcaseImg4}`}
                                                        alt=""
                                                        className="homepage-service-img"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card-flyer mb-2">
                                            <div class="text-box">
                                                <div class="image-box">
                                                <img src={`https://roomyy.life/AboutShowcases/${val?.AboutShowcaseImg5}`}
                                                        alt=""
                                                        className="homepage-service-img"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card-flyer mb-2">
                                            <div class="text-box">
                                                <div class="image-box">
                                                <img src={`https://roomyy.life/AboutShowcases/${val?.AboutShowcaseImg6}`}
                                                        alt=""
                                                        className="homepage-service-img"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    );
                })}
            </Container>

            {/* <Container>
                <div>
                    <div>
                        <h1 className='content-title mt-5 mb-3'>
                            Meet the Minds <br />Behind the Projects</h1>
                        <h2>The Faces Behind Our Success, Meet Our Interior Design Experts.</h2>
                    </div>
                    <div>
                        <div className="about-display-container mt-3">
                            <div id="cards_landscape_wrap-2ss">
                                <div className="service-cards">


                                    <div class="card-flyer mt-5">
                                        <div class="text-box">
                                            <div class="image-box">
                                                <img
                                                    src="../Assets/user1.jpg"
                                                    alt=""
                                                    className="homepage-service-img"
                                                    style={{ height: "390px" }}
                                                />
                                            </div>

                                            <div class="overlay text-container">
                                                <h6 className=''>Alisson Taylor</h6>
                                                <h5>Team Manager</h5>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-flyer ">
                                        <div class="text-box">
                                            <div class="image-box">
                                                <img
                                                    src="../Assets/user2.jpg"
                                                    alt=""
                                                    className="homepage-service-img"
                                                    style={{ height: "390px" }}

                                                />
                                            </div>

                                            <div class="overlay text-container ">
                                                <h6>Roger Craig</h6>
                                                <h5>CEO</h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-flyer mt-5">
                                        <div class="text-box">
                                            <div class="image-box">
                                                <img
                                                    src="../Assets/user3.jpg"
                                                    alt=""
                                                    className="homepage-service-img"
                                                    style={{ height: "390px" }}

                                                />
                                            </div>

                                            <div class=" overlay text-container ">
                                                <h6>Allan Cooper</h6>
                                                <h5>CTO</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Container>
                <div className='d-flex justify-content-space-between'>
                    <div>
                        <h1 className='content-title mt-5 mb-3'>
                            Get in Touch</h1>
                        <h2>Building Relationships, One Conversation at a Time</h2>
                    </div>
                    <div style={{ margin: "auto" }}>
                        <div className='d-flex'>
                            <a href='/contactus'>
                                <p style={{ color: "black", fontSize: "20px" }}>Contact us <FaArrowTurnUp /></p>
                            </a>
                        </div>

                    </div>
                </div>
            </Container> */}
        </div>
    )
}

export default About