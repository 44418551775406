import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import parse from 'html-react-parser';


const AdminFabrication = () => {

  // Add modal Renovats
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setShow(true);
    setData1(item);
  }

  // Edit modal Renovats
  // const [show1, setShow1] = useState();
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setFabricationDesc1(item.FabricationDesc1);
    setFabricationTitle(item.FabricationTitle);
    setFabricationDesc2(item.FabricationDesc2);
    setData1(item);
    setShow1(true);
  }
  // Delete modal Renovats
  const [show2, setShow2] = useState();
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);


  // ======INTEGRATION FOR RENOVATION=======//

  // integrating post method
  const formdata = new FormData();
  const [FabricationDesc1, setFabricationDesc1] = useState("");
  const [FabricationTitle, setFabricationTitle] = useState("");
  const [FabricationDesc2, setFabricationDesc2] = useState("");


  const AddFabricationDetails = async () => {
    formdata.append("FabricationDesc1", FabricationDesc1);
    formdata.append("FabricationTitle", FabricationTitle);
    formdata.append("FabricationDesc2", FabricationDesc2);

    try {

      if (!FabricationDesc1) {
        return alert("Please add Description");
      }
      if (!FabricationTitle) {
        return alert("Please add Title");
      }
      if (!FabricationDesc2) {
        return alert("Please add Description");
      }
      const config = {
        url: "/admin/fabrication",
        method: "post",
        baseURL: "https://roomyy.life/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddFabrication();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddFabrication, setAddFabrication] = useState([]);
  const getAddFabrication = async () => {
    try {
      let res = await axios.get(
        "https://roomyy.life/api/admin/getfabrication"
      );
      if (res.status === 200) {
        setAddFabrication(res.data.getfabrication);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const DeleteFabrication = async () => {
    try {
      const config = {
        url: "admin/Deletefabrication/" + Data,
        method: "delete",
        baseURL: "https://roomyy.life/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddFabrication();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const [show1, setShow1] = useState(false);

  const EditFabrication = async (e) => {
    e.preventDefault();
    formdata.append("FabricationDesc1", FabricationDesc1);
    formdata.append("FabricationTitle", FabricationTitle);
    formdata.append("FabricationDesc2", FabricationDesc2);

    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "admin/editfabrication",
        method: "put",
        baseURL: "https://roomyy.life/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getAddFabrication();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };
  useEffect(() => {
    getAddFabrication();
  }, []);
  console.log(AddFabrication);

  // pagination
  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 5;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  const records = AddFabrication.slice(firstIndex, lastIndex);
  const npages = Math.ceil(AddFabrication.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }


  // CKEditor 
  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setFabricationDesc1(data);
  };

  const handleChange2 = (e, editor) => {
    const data = editor.getData();
    setFabricationDesc2(data);
  };

  return (
    <div>
      <div className="col-lg-4 d-flex justify-content-center">
        <div class="input-group ">
          <span class="input-group-text" id="basic-addon1">
            <BsSearch />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="header-c ">Fabrication</h2>
        <div className="d-flex gap-3">
          <button className="admin-add-btn" onClick={handleShow}>
            Add Fabrication
          </button>

        </div>

      </div>

      <div className="mb-3">
        <Table
          responsive
          bordered
          style={{ width: "-webkit-fill-available" }}
        >
          <thead>
            <tr>
              <th>Description</th>
              <th>Title</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {records?.map((item, i) => {
              return (
                <tr key={i}>

                  <td style={{ paddingTop: "20px" }}>{parse(`<div>${item.FabricationDesc1}</div>`)}</td>

                  <td style={{ paddingTop: "20px" }}>{item.FabricationTitle}</td>



                  <td style={{ paddingTop: "20px" }}>{parse(`<div>${item.FabricationDesc2}</div>`)}</td>



                  <td>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <BiSolidEdit
                          className="text-success"
                          style={{ cursor: "pointer", fontSize: "20px" }}
                          onClick={() => {
                            handleShow1(item);
                            setData1(item);
                          }
                          }
                        />{" "}
                      </div>
                      <div>
                        <AiFillDelete
                          className="text-danger"
                          style={{ cursor: "pointer", fontSize: "20px" }}
                          onClick={() => {
                            handleShow2();
                            setData(item?._id)
                          }}

                        />
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      {/* Add Package modal */}
      <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
        <Modal.Header
          closeButton

        >
          <Modal.Title style={{ color: "black" }}>Add Fabrication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Description</label>
              <CKEditor editor={ClassicEditor}
                onChange={handleChange1}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Title</label>
              <input
                type="text"
                className="vi_0"
                placeholder="Enter Banner Title"
                onChange={(e) => setFabricationTitle(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Description</label>
              <CKEditor editor={ClassicEditor}
                onChange={handleChange2}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <Button
              className="mx-2 modal-close-btn"
              variant=""
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              className="mx-2 modal-add-btn"
              variant=""
              onClick={AddFabricationDetails}
            >
              Add
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Edit Package modal */}
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
        style={{ zIndex: "99999" }}
      >
        <Modal.Header
          closeButton
        >
          <Modal.Title style={{ color: "black" }}>Edit Fabrication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Description</label>
              <CKEditor editor={ClassicEditor}
                data={FabricationDesc1}
                onChange={handleChange1}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Title</label>
              <input
                type="text"
                className="vi_0"
                placeholder="Enter Banner Title"
                value={FabricationTitle}
                onChange={(e) => setFabricationTitle(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Description</label>
              <CKEditor editor={ClassicEditor}
                data={FabricationDesc2}
                onChange={handleChange2}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            className="modal-close-btn"
            onClick={handleClose1}
          >
            Close
          </Button>
          <Button variant="" className="modal-add-btn"
            onClick={EditFabrication}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delet modal  */}
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
        style={{ zIndex: "99999" }}
      >
        <Modal.Header
          closeButton
        >
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <p className="fs-4" style={{ color: "red" }}>
                Are you sure?
                <br /> you want to delete this data?
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            className="modal-close-btn"
            onClick={handleClose2}
          >
            Close
          </Button>
          <Button
            variant=""
            className="modal-add-btn"
            onClick={DeleteFabrication}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>


    </div>
  )
}

export default AdminFabrication