import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image, Container } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import parse from 'html-react-parser';
import { IoEye } from "react-icons/io5";


const AdminInterior = () => {

  // CKEditor 
  const handleChange = (e, editor) => {
    const data = editor.getData();
    setInteriorDesc(data);
  };

  //Add Modal for Slider
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setData1(item);
  };

  // Edit modal for  Slider
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (item) => {
    setInteriorImage(item.InteriorImage);
    setInteriorTitle(item.InteriorTitle);
    setInteriorDesc(item.InteriorDesc);
    setInteriorSliderImg1(item.InteriorSliderImg1);
    setInteriorSliderImg2(item.InteriorSliderImg2);
    setInteriorSliderImg3(item.InteriorSliderImg3);
    setInteriorSliderImg4(item.InteriorSliderImg4);
    setInteriorSliderImg5(item.InteriorSliderImg5);
    setData1(item);
    setShow4(true);
  }

  // Delet modal for  Banner
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // Image View 
  const [View, setView] = useState({});
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);


  // ======INTEGRATION=======//

  // integrating post method
  const formdata = new FormData();
  const [InteriorImage, setInteriorImage] = useState("");
  const [InteriorTitle, setInteriorTitle] = useState("");
  const [InteriorDesc, setInteriorDesc] = useState("");
  const [InteriorSliderImg1, setInteriorSliderImg1] = useState("");
  const [InteriorSliderImg2, setInteriorSliderImg2] = useState("");
  const [InteriorSliderImg3, setInteriorSliderImg3] = useState("");
  const [InteriorSliderImg4, setInteriorSliderImg4] = useState("");
  const [InteriorSliderImg5, setInteriorSliderImg5] = useState("");

  const AddInteriorDetails = async () => {
    formdata.append("InteriorImage", InteriorImage);
    formdata.append("InteriorTitle", InteriorTitle);
    formdata.append("InteriorDesc", InteriorDesc);
    formdata.append("InteriorSliderImg1", InteriorSliderImg1);
    formdata.append("InteriorSliderImg2", InteriorSliderImg2);
    formdata.append("InteriorSliderImg3", InteriorSliderImg3);
    formdata.append("InteriorSliderImg4", InteriorSliderImg4);
    formdata.append("InteriorSliderImg5", InteriorSliderImg5);
    try {
      if (!InteriorImage) {
        return alert("Please add Image");
      }
      if (!InteriorTitle) {
        return alert("Please add Title");
      }
      if (!InteriorDesc) {
        return alert("Please add Description");
      }
      if (!InteriorSliderImg1) {
        return alert("Please add Image");
      }
      if (!InteriorSliderImg2) {
        return alert("Please add Image");
      }
      if (!InteriorSliderImg3) {
        return alert("Please add Image");
      }
      if (!InteriorSliderImg4) {
        return alert("Please add Image");
      }
      if (!InteriorSliderImg4) {
        return alert("Please add Image");
      }
      const config = {
        url: "/admin/interior",
        method: "post",
        baseURL: "https://roomyy.life/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddInterior();
        handleClose3();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddInterior, setAddInterior] = useState([]);
  const getAddInterior = async () => {
    try {
      let res = await axios.get(
        "https://roomyy.life/api/admin/getinterior"
      );
      if (res.status === 200) {
        setAddInterior(res.data.getinterior);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const DeleteInterior = async () => {
    try {
      const config = {
        url: "admin/Deleteinterior/" + Data,
        method: "delete",
        baseURL: "https://roomyy.life/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddInterior();
          handleClose5();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const [show3, setShow3] = useState(false);

  const EditInteror = async (e) => {
    e.preventDefault();
    formdata.append("InteriorImage", InteriorImage);
    formdata.append("InteriorTitle", InteriorTitle);
    formdata.append("InteriorDesc", InteriorDesc);
    formdata.append("InteriorSliderImg1", InteriorSliderImg1);
    formdata.append("InteriorSliderImg2", InteriorSliderImg2);
    formdata.append("InteriorSliderImg3", InteriorSliderImg3);
    formdata.append("InteriorSliderImg4", InteriorSliderImg4);
    formdata.append("InteriorSliderImg5", InteriorSliderImg5);

    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "admin/editinterior",
        method: "put",
        baseURL: "https://roomyy.life/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose4();
          getAddInterior();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };
  useEffect(() => {
    getAddInterior();
  }, []);
  console.log(AddInterior);

  // pagination
  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 5;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  const records = AddInterior.slice(firstIndex, lastIndex);
  const npages = Math.ceil(AddInterior.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }


  return (
    <div>
      <div className="col-lg-4 d-flex justify-content-center">
        <div class="input-group ">
          <span class="input-group-text" id="basic-addon1">
            <BsSearch />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
          />
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <h2 className="header-c ">Interior</h2>
        <div className="d-flex gap-3">
          <button className="admin-add-btn" onClick={handleShow3}>
            Add Interior
          </button>

        </div>

      </div>

      <div className="mb-3">
        <Table
          responsive
          bordered
          style={{ width: "-webkit-fill-available" }}
        >
          <thead>
            <tr>
              <th>S.No</th>
              <th>Image</th>
              <th>Heading</th>
              <th>Description</th>
              <th>Slider Image</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {records?.map((item, i) => {
              return (

                <tr key={i}>
                  <td>{i + 1 + firstIndex}</td>
                  <td>
                    <Image
                      src={`https://roomyy.life/Interior/${item?.InteriorImage}`}
                      alt="pic"
                      style={{ width: "75px", height: "75px" }}
                    />
                  </td>
                  <td style={{ paddingTop: "20px" }}>{item.InteriorTitle}</td>
                  <td style={{ paddingTop: "20px" }}>{parse(`<div>${item.InteriorDesc}</div>`)}</td>
                  <td>
                    <IoEye
                      style={{ fontSize: "25px", color: "green" }}
                      onClick={() => {
                        handleShow6(item);
                        setView(item);
                      }}
                    />
                  </td>
                  <td>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <BiSolidEdit
                          className="text-success"
                          style={{ cursor: "pointer", fontSize: "20px" }}
                          onClick={() => {
                            handleShow4(item);
                            setData1(item);
                          }
                          }
                        />{" "}
                      </div>
                      <div>
                        <AiFillDelete
                          className="text-danger"
                          style={{ cursor: "pointer", fontSize: "20px" }}
                          onClick={() => {
                            handleShow5();
                            setData(item?._id);
                          }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      {/* Add Package modal */}
      <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
        <Modal.Header
          closeButton

        >
          <Modal.Title style={{ color: "black" }}>Add Interior</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setInteriorImage(e.target.files[0])}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Title</label>
              <input
                type="text"
                className="vi_0"
                placeholder="Enter Banner Title"
                onChange={(e) => setInteriorTitle(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Description</label>
              <CKEditor editor={ClassicEditor}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Slider Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setInteriorSliderImg1(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Slider Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setInteriorSliderImg2(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Slider Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setInteriorSliderImg3(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Slider Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setInteriorSliderImg4(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Slider Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setInteriorSliderImg5(e.target.files[0])}
              />
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <Button
              className="mx-2 modal-close-btn"
              variant=""
              onClick={handleClose3}
            >
              Close
            </Button>
            <Button
              className="mx-2 modal-add-btn"
              variant=""
              onClick={AddInteriorDetails}
            >
              Add
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Edit Package modal */}
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
        style={{ zIndex: "99999" }}
      >
        <Modal.Header
          closeButton
        >
          <Modal.Title style={{ color: "black" }}>Edit Interior</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setInteriorImage(e.target.files[0])}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Title</label>
              <input
                type="text"
                className="vi_0"
                value={InteriorTitle}
                placeholder="Enter Banner Title"
                onChange={(e) => setInteriorTitle(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Description</label>
              <CKEditor editor={ClassicEditor}
                data={InteriorDesc}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Slider Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setInteriorSliderImg1(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Slider Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setInteriorSliderImg2(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Slider Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setInteriorSliderImg3(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Slider Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setInteriorSliderImg4(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Slider Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setInteriorSliderImg5(e.target.files[0])}
              />
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            className="modal-close-btn"
            onClick={handleClose4}
          >
            Close
          </Button>
          <Button variant="" className="modal-add-btn"
            onClick={EditInteror}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delet modal  */}
      <Modal
        show={show5}
        onHide={handleClose5}
        backdrop="static"
        keyboard={false}
        style={{ zIndex: "99999" }}
      >
        <Modal.Header
          closeButton
        >
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <p className="fs-4" style={{ color: "red" }}>
                Are you sure?
                <br /> you want to delete this data?
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            className="modal-close-btn"
            onClick={handleClose5}
          >
            Close
          </Button>
          <Button
            variant=""
            className="modal-add-btn"
            onClick={DeleteInterior}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

       {/* Slider Image modal  */}
       <Modal
          show={show6}
          onHide={handleClose6}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title style={{ color: "black" }}>Interior Slider Images</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className="row">
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/Interior/${View?.InteriorSliderImg1}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/Interior/${View?.InteriorSliderImg2}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/Interior/${View?.InteriorSliderImg3}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/Interior/${View?.InteriorSliderImg4}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/Interior/${View?.InteriorSliderImg5}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
              </div>
            </Container>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose6}
            >
              Close
            </Button>

          </Modal.Footer>
        </Modal>

    </div>
  )
}

export default AdminInterior