import { BrowserRouter, Route, Routes } from "react-router-dom";

import Slider from "./Components/Slider";
import Header from "./Components/Header";
import About from "./Components/About";
import Renovation from "./Components/Renovation";
import Interior from "./Components/Interior";
import Fabrication from "./Components/Fabrication";
import CeilingSolution from "./Components/CeilingSolution";
import Gallery from "./Components/Gallery";
import Contactus from "./Components/Contactus";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Login from "./Components/Login";
import Register from "./Components/Register";


// Admin panel 

import Dashboard from "./Components/Admin/Dashboard";
import Main from "./Components/Admin/Main";
import AdminSlider from "./Components/Admin/AdminSlider";
import AdminContactus from "./Components/Admin/AdminContactus"
import AdminRenovation from "./Components/Admin/AdminRenovation";
import AdminInterior from "./Components/Admin/AdminInterior";
import AdminFabrication from "./Components/Admin/AdminFabrication";
import AdminCeilingSolution from "./Components/Admin/AdminCeilingSolution";
import AdminGallery from "./Components/Admin/AdminGallery";
import AdminLogin from "./Components/Admin/AdminLogin";
import AdminServices from "./Components/Admin/AdminServices";
import AdminHomePhilosophy from "./Components/Admin/AdminHomePhilosophy";
import AdminHomeCategory from "./Components/Admin/AdminHomeCategory";
import AdminWhyChooseus from "./Components/Admin/AdminWhyChooseus";
import AboutusBanner from "./Components/Admin/AboutusBanner";
import Aboutus from "./Components/Admin/Aboutus";
import AboutOurServices from "./Components/Admin/AboutOurServices";
import AboutOurPassion from "./Components/Admin/AboutOurPassion";
import AboutShowcases from "./Components/Admin/AboutShowcases";
import GeneralEnquiry from "./Components/Admin/GeneralEnquiry";
import CategoryEnquiry from "./Components/Admin/CategoryEnquiry";
import BookingList from "./Components/Admin/BookingList";
import UserList from "./Components/Admin/UserList";
import AdminRenoationBanner from "./Components/Admin/AdminRenoationBanner";
import AdminInteriorBanner from "./Components/Admin/AdminInteriorBanner";
import FindBetterInterior from "./Components/Admin/FindBetterInterior";
import FabricationBanner from "./Components/Admin/FabricationBanner";
import FabricationTypes from "./Components/Admin/FabricationTypes";
import CeilingBanner from "./Components/Admin/CeilingBanner";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <Slider />
                <Home />
                <Footer />
              </>
            }
          />
          <Route
            path="/aboutus"
            element={
              <>
                <Header />
                <About />
                <Footer />
              </>
            }
          />

          <Route
            path="/renovation"
            element={
              <>
                <Header />
                <Renovation />
                <Footer />
              </>
            }
          />

          <Route
            path="/interior"
            element={
              <>
                <Header />
                <Interior />
                <Footer />
              </>
            }
          />

          <Route
            path="/fabrication"
            element={
              <>
                <Header />
                <Fabrication />
                <Footer />
              </>
            }
          />

          <Route
            path="/ceilingsolution"
            element={
              <>
                <Header />
                <CeilingSolution />
                <Footer />
              </>
            }
          />

          <Route
            path="/gallery"
            element={
              <>
                <Header />
                <Gallery />
                <Footer />
              </>
            }
          />

          <Route
            path="/contactus"
            element={
              <>
                <Header />
                <Contactus />
                <Footer />
              </>
            }
          />

          <Route
            path="/login"
            element={
              <>
                <Header />
                <Login />
                <Footer />
              </>
            }
          />
          {/* <Route
            path="/register"
            element={
              <>
                <Header />
                <Register />
                <Footer />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <Header />
                <Login />
                <Footer />
              </>
            }
          /> */}


          {/* Admin panel  */}

          <Route
            path="/admin"
            element={
              <>
                <AdminLogin />
              </>

            }
          />

          {/* <Route
            path="/dashboard"
            element={
              <Main
                children={
                  <>
                    <Dashboard />
                  </>
                }
              />
            }
          /> */}

          {/* HOME  */}

          <Route
            path="/admin_homeslider"
            element={
              <Main
                children={
                  <>
                    <AdminSlider />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_homeservices"
            element={
              <Main
                children={
                  <>
                    <AdminServices />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin_homephilosophy"
            element={
              <Main
                children={
                  <>
                    <AdminHomePhilosophy />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin_homecategory"
            element={
              <Main
                children={
                  <>
                    <AdminHomeCategory />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin_homewhychooseus"
            element={
              <Main
                children={
                  <>
                    <AdminWhyChooseus />
                  </>
                }
              />
            }
          />

          {/* ABOUT US  */}
          <Route
            path="/admin_aboutbanner"
            element={
              <Main
                children={
                  <>
                    <AboutusBanner />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_aboutus"
            element={
              <Main
                children={
                  <>
                    <Aboutus />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin_aboutourservices"
            element={
              <Main
                children={
                  <>
                    <AboutOurServices />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin_aboutourpassion"
            element={
              <Main
                children={
                  <>
                    <AboutOurPassion />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin_aboutshowcases"
            element={
              <Main
                children={
                  <>
                    <AboutShowcases />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_renovation"
            element={
              <Main
                children={
                  <>
                    <AdminRenovation />
                  </>
                }
              />
            }
          />

          <Route
            path="/renovationbanner"
            element={
              <Main
                children={
                  <>
                    <AdminRenoationBanner />
                  </>
                }
              />
            }
          />
          {/* INTERIOR  */}
          <Route
            path="/admin_interiorbanner"
            element={
              <Main
                children={
                  <>
                    <AdminInteriorBanner />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin_interior"
            element={
              <Main
                children={
                  <>
                    <AdminInterior />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin_findbetterinterior"
            element={
              <Main
                children={
                  <>
                    <FindBetterInterior />
                  </>
                }
              />
            }
          />
          {/* FABRICATION  */}
          <Route
            path="/admin_fabricationbanner"
            element={
              <Main
                children={
                  <>
                    <FabricationBanner />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin_fabrication"
            element={
              <Main
                children={
                  <>
                    <AdminFabrication />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin_fabricationtypes"
            element={
              <Main
                children={
                  <>
                    <FabricationTypes />
                  </>
                }
              />
            }
          />

          {/* CEILING SOLUTION  */}

          <Route
            path="/admin_ceilingbanner"
            element={
              <Main
                children={
                  <>
                    <CeilingBanner />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_ceilingsolution"
            element={
              <Main
                children={
                  <>
                    <AdminCeilingSolution />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin_gallery"
            element={
              <Main
                children={
                  <>
                    <AdminGallery />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_contactus"
            element={
              <Main
                children={
                  <>
                    <AdminContactus />
                  </>
                }
              />
            }
          />
          <Route
            path="/generalenquiry"
            element={
              <Main
                children={
                  <>
                    <GeneralEnquiry />
                  </>
                }
              />
            }
          />

          <Route
            path="/serviceenquiry"
            element={
              <Main
                children={
                  <>
                    <CategoryEnquiry />
                  </>
                }
              />
            }
          />

          <Route
            path="/bookinglist"
            element={
              <Main
                children={
                  <>
                    <BookingList />
                  </>
                }
              />
            }
          />
          <Route
            path="/userlist"
            element={
              <Main
                children={
                  <>
                    <UserList />
                  </>
                }
              />
            }
          />


        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
