import React, { useState, useEffect } from "react";
import "../Styles/interior.css"
import { Container } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Aos from "aos";
import { FaArrowTurnUp } from "react-icons/fa6";
import axios from "axios";
import parse from 'html-react-parser';

const Interior = () => {


  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  });

  const options1 = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 4,
      },
    },
  };

  // ==========Banner=========//
  //integrating get  method
  const [AddInteriorBanner, setAddInteriorBanner] = useState([]);
  const getAddInteriorBanner = async () => {
    try {
      let res = await axios.get(
        "https://roomyy.life/api/admin/getinteriorbanner"
      );
      if (res.status === 200) {
        setAddInteriorBanner(res.data.getinteriorbanner);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddInteriorBanner();
  }, []);

  // =========INTERIOR ==========//
  //integrating get  method
  const [AddInterior, setAddInterior] = useState([]);
  const getAddInterior = async () => {
    try {
      let res = await axios.get(
        "https://roomyy.life/api/admin/getinterior"
      );
      if (res.status === 200) {
        setAddInterior(res.data.getinterior);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddInterior();
  }, []);


  // =============FIND BETTER===========//
  //integrating get  method
  const [AddFindBetter, setAddFindBetter] = useState([]);
  const getAddFindBetter = async () => {
    try {
      let res = await axios.get(
        "https://roomyy.life/api/admin/getfindbetter"
      );
      if (res.status === 200) {
        setAddFindBetter(res.data.getfindbetter);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddFindBetter();
  }, []);

  return (
    <div>
      {AddInteriorBanner?.map((val, i) => {
        return (
          <div className="aboutbg-img'">
            <img src={`https://roomyy.life/InteriorBanner/${val?.InteriorBannerImage}`}
              alt=''
              className='about-img'
            />
            <div>
              <h2 className="headding">
                {parse(`<div>${val?.InteriorBannerCaption}</div>`)}
              </h2>
            </div>

            <div className="fixed-icon">
              <div>
                <a href='https://wa.link/wvrxiz' target="_new">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    viewBox="0,0,256,256"
                    width="43px"
                    height="43px"
                  >
                    <g transform="translate(51.2,51.2) scale(0.6,0.6)">
                      <g
                        fill="green "
                        fill-rule="nonzero"
                        stroke="none"
                        stroke-width="1"
                        stroke-linecap="butt"
                        stroke-linejoin="miter"
                        stroke-miterlimit="10"
                        stroke-dasharray=""
                        stroke-dashoffset="0"
                        font-family="none"
                        font-weight="none"
                        font-size="none"
                        text-anchor="none"
                        style={{ mixBlendMode: "normal" }}
                      >
                        <g transform="scale(5.12,5.12)">
                          <path d="M25,2c-12.682,0 -23,10.318 -23,23c0,3.96 1.023,7.854 2.963,11.29l-2.926,10.44c-0.096,0.343 -0.003,0.711 0.245,0.966c0.191,0.197 0.451,0.304 0.718,0.304c0.08,0 0.161,-0.01 0.24,-0.029l10.896,-2.699c3.327,1.786 7.074,2.728 10.864,2.728c12.682,0 23,-10.318 23,-23c0,-12.682 -10.318,-23 -23,-23zM36.57,33.116c-0.492,1.362 -2.852,2.605 -3.986,2.772c-1.018,0.149 -2.306,0.213 -3.72,-0.231c-0.857,-0.27 -1.957,-0.628 -3.366,-1.229c-5.923,-2.526 -9.791,-8.415 -10.087,-8.804c-0.295,-0.389 -2.411,-3.161 -2.411,-6.03c0,-2.869 1.525,-4.28 2.067,-4.864c0.542,-0.584 1.181,-0.73 1.575,-0.73c0.394,0 0.787,0.005 1.132,0.021c0.363,0.018 0.85,-0.137 1.329,1.001c0.492,1.168 1.673,4.037 1.819,4.33c0.148,0.292 0.246,0.633 0.05,1.022c-0.196,0.389 -0.294,0.632 -0.59,0.973c-0.296,0.341 -0.62,0.76 -0.886,1.022c-0.296,0.291 -0.603,0.606 -0.259,1.19c0.344,0.584 1.529,2.493 3.285,4.039c2.255,1.986 4.158,2.602 4.748,2.894c0.59,0.292 0.935,0.243 1.279,-0.146c0.344,-0.39 1.476,-1.703 1.869,-2.286c0.393,-0.583 0.787,-0.487 1.329,-0.292c0.542,0.194 3.445,1.604 4.035,1.896c0.59,0.292 0.984,0.438 1.132,0.681c0.148,0.242 0.148,1.41 -0.344,2.771z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        );
      })}

      <Container>
        {AddInterior?.map((val, i) => {
          return (
            <div className='interior-main '>
              <div className='interior-mains'>
                <div className='Carousell mt-2'>
                  <img
                    src={`https://roomyy.life/Interior/${val?.InteriorImage}`} alt='' className='interior-image' data-aos="zoom-in"
                    data-aos-delay="50"
                    data-aos-duration="3000" />
                </div>
                <div className='interior-content'>
                  <h4>
                    {parse(`<div>${val?.InteriorTitle}</div>`)}
                  </h4>
                  <p className='interior-para' data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="3000">
                    {parse(`<div>${val?.InteriorDesc}</div>`)}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </Container>
      {/* =======SLIDER====== */}
      <Container className='' data-aos="zoom-in"
        data-aos-delay="50"
        data-aos-duration="3000">
        {AddInterior?.map((val, i) => {
          return (
            <OwlCarousel
              loop
              margin={5}
              nav
              {...options1}
            >
              <div class="item">
                <div class="card" >
                  <div class="image-box">
                    <img src={`https://roomyy.life/Interior/${val?.InteriorSliderImg1}`} alt='' className='imgsss' />
                  </div>

                </div>
              </div>

              <div class="item">
                <div class="card" >
                  <div class="image-box">
                    <img src={`https://roomyy.life/Interior/${val?.InteriorSliderImg2}`} alt='' className='imgsss' />
                  </div>

                </div>
              </div>

              <div class="item">
                <div class="card">
                  <div class="image-box">
                    <img src={`https://roomyy.life/Interior/${val?.InteriorSliderImg3}`} alt='' className='imgsss' />
                  </div>

                </div>
              </div>

              <div class="item">
                <div class="card">
                  <div class="image-box">
                    <img src={`https://roomyy.life/Interior/${val?.InteriorSliderImg4}`} alt='' className='imgsss' />
                  </div>

                </div>
              </div>

              <div class="item">
                <div class="card">
                  <div class="image-box">
                    <img src={`https://roomyy.life/Interior/${val?.InteriorSliderImg5}`} alt='' className='imgsss' />
                  </div>

                </div>
              </div>
            </OwlCarousel>
          );
        })}
      </Container>


      {/* ==============FIND BETTER============= */}
      <Container >
        {AddFindBetter?.map((val, i) => {
          return (

            <div className='container-content'>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='' style={{ color: "white" }}>
                    <h1>
                    {parse(`<div>${val?.FindBetterTitle}</div>`)}
                    </h1>
                    <p className='mt-4'>
                    {parse(`<div>${val?.FindBetterDesc}</div>`)}
                    </p>

                  </div>
                </div>
                <div className='col-md-4'>
                  <img src={`https://roomyy.life/FindBetter/${val?.FindBetterImage1}`}
                  alt="" 
                  className='img-top' data-aos="zoom-in"
                    data-aos-delay="50"
                    data-aos-duration="3000" />
                </div>
                <div className='col-md-4'>
                  <div className='mb-2 kjdchjdd'>
                    <img src={`https://roomyy.life/FindBetter/${val?.FindBetterImage2}`} alt="" className='img-bottom' data-aos="fade-down"
                      data-aos-delay="50"
                      data-aos-duration="3000" />
                  </div>
                  <div className='kjdchjdd'>
                    <img src={`https://roomyy.life/FindBetter/${val?.FindBetterImage3}`} alt="" className='img-bottom-circle' data-aos="fade-up"
                      data-aos-delay="50"
                      data-aos-duration="3000" />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Container>
    </div>
  )
}

export default Interior