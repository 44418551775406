import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import parse from 'html-react-parser';

const AdminRenovation = () => {

  // Add modal Renovats
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setShow(true);
    setData1(item);
  }

  // Edit modal Renovats
  // const [show1, setShow1] = useState();
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setRenovationHeading(item.RenovationHeading);
    setRenovationImage(item.RenovationImage);
    setRenovationTitle(item.RenovationTitle);
    setRenovationDesc(item.RenovationDesc);
    setData1(item);
    setShow1(true);
  }
  // Delete modal Renovats
  const [show2, setShow2] = useState();
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);


  // CKEditor 
  const handleChange = (e, editor) => {
    const data = editor.getData();
    setRenovationDesc(data);
  };


  // Condition variables 
  const [Banner, setBanner] = useState(true);
  const [Renovats, setRenovats] = useState(false);

  // ======INTEGRATION FOR RENOVATION=======//

  // integrating post method
  const formdata = new FormData();
  const [RenovationHeading, setRenovationHeading] = useState("");
  const [RenovationImage, setRenovationImage] = useState("");
  const [RenovationTitle, setRenovationTitle] = useState("");
  const [RenovationDesc, setRenovationDesc] = useState("");


  const AddRenovationDetails = async () => {
    formdata.append("RenovationHeading", RenovationHeading);
    formdata.append("RenovationImage", RenovationImage);
    formdata.append("RenovationTitle", RenovationTitle);
    formdata.append("RenovationDesc", RenovationDesc);

    try {

      if (!RenovationHeading) {
        // return alert("Please add Heading");
      }
      if (!RenovationImage) {
        return alert("Please add Image");
      }
      if (!RenovationTitle) {
        return alert("Please add Title");
      }
      if (!RenovationDesc) {
        return alert("Please add Description");
      }
      const config = {
        url: "/admin/renovation",
        method: "post",
        baseURL: "https://roomyy.life/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddRenovation();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddRenovation, setAddRenovation] = useState([]);
  const getAddRenovation = async () => {
    try {
      let res = await axios.get(
        "https://roomyy.life/api/admin/getrenovation"
      );
      if (res.status === 200) {
        setAddRenovation(res.data.getrenovation);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const DeleteRenovation = async () => {
    try {
      const config = {
        url: "admin/Deleterenovation/" + Data,
        method: "delete",
        baseURL: "https://roomyy.life/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddRenovation();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const [show1, setShow1] = useState(false);

  const EditRenovation = async (e) => {
    e.preventDefault();
    formdata.append("RenovationHeading", RenovationHeading);
    formdata.append("RenovationImage", RenovationImage);
    formdata.append("RenovationTitle", RenovationTitle);
    formdata.append("RenovationDesc", RenovationDesc);

    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "admin/editrenovation",
        method: "put",
        baseURL: "https://roomyy.life/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getAddRenovation();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };
  useEffect(() => {
    getAddRenovation();
  }, []);
  console.log(AddRenovation);

  // pagination
  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 5;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  const records = AddRenovation.slice(firstIndex, lastIndex);
  const npages = Math.ceil(AddRenovation.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }

  return (
    <div>
      <div className="col-lg-4 d-flex justify-content-center">
        <div class="input-group ">
          <span class="input-group-text" id="basic-addon1">
            <BsSearch />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
          />
        </div>
      </div>

        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Renovation</h2>
          <div className="d-flex gap-3">
            <button className="admin-add-btn" onClick={handleShow}>
              Add Renovation
            </button>

          </div>

        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>Title</th>
                <th>Image</th>
                <th>Heading</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {records?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>
                      {i + 1 + firstIndex}

                    </td>
                    <td>
                      <td style={{ paddingTop: "20px" }}>{item.RenovationHeading}</td>

                    </td>
                    <td>
                      <Image
                        src={`https://roomyy.life/Renovation/${item?.RenovationImage}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />                </td>
                    <td>
                      <td style={{ paddingTop: "20px" }}>{item.RenovationTitle}</td>

                    </td>
                    <td>
                      <td style={{ paddingTop: "20px" }}>{parse(`<div>${item.RenovationDesc}</div>`)}</td>

                    </td>

                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className="text-success"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow1(item);
                              setData1(item);
                            }
                            }
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className="text-danger"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow2();
                              setData(item?._id)
                            }}

                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Package modal Renovation*/}
        <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
          <Modal.Header
            closeButton

          >
            <Modal.Title style={{ color: "black" }}>Add Renovation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Title</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  onChange={(e) => setRenovationHeading(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setRenovationImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Heading</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  onChange={(e) => setRenovationTitle(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Description</label>
                <CKEditor editor={ClassicEditor}
                  onChange={handleChange}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={AddRenovationDetails}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal Renovation*/}
        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title style={{ color: "black" }}>Edit Renovation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Title</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  value={RenovationHeading}
                  onChange={(e) => setRenovationHeading(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setRenovationImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Heading</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  value={RenovationTitle}
                  onChange={(e) => setRenovationTitle(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Description</label>
                <CKEditor editor={ClassicEditor}
                  data={RenovationDesc}
                  onChange={handleChange}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose1}
            >
              Close
            </Button>
            <Button variant="" className="modal-add-btn"
              onClick={EditRenovation}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delet modal Renovation */}
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose2}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={DeleteRenovation}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

    </div>
  )
}

export default AdminRenovation