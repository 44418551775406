import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CategoryEnquiry = () => {

  const [show4, setShow4] = useState();
  const [Data, setData] = useState("");
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e, editor) => {
    const data = editor.getData();
    // setCUMessage(data);
  };


  return (
    <div>
      <div>
        <div className="col-lg-4 d-flex justify-content-center">
          <div class="input-group ">
            <span class="input-group-text" id="basic-addon1">
              <BsSearch />
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
        <div className="customerhead p-2">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="header-c ">Service Enquiry</h2>
            <button className="admin-add-btn" onClick={handleShow}>
              Add Service Enquiry
            </button>
          </div>

          <div className="mb-3">
            <Table
              responsive
              bordered
              style={{ width: "-webkit-fill-available" }}
            >
              <thead>
                <tr>
                  <th>SL.NO</th>
                  <th>Category</th>
                  <th>Name</th>
                  <th>Email ID</th>
                  <th>Phone Number</th>
                  <th>Message</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                
                    <tr>
                      <td>1</td>
                      <td style={{ paddingTop: "20px" }}>Service Name</td>
                      <td style={{ paddingTop: "20px" }}>Name</td>
                    <td style={{ paddingTop: "20px" }}>email</td>
                    <td style={{ paddingTop: "20px" }}>Contact number</td>
                    <td style={{ paddingTop: "20px" }}>Message</td>

                      <td>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <AiFillDelete
                              className="text-danger"
                              style={{ cursor: "pointer", fontSize: "20px" }}
                              onClick={() => {
                                handleShow4();
                                // setData(item?._id);
                              }}
                            />{" "}
                          </div>
                        </div>
                      </td>
                    </tr>
              </tbody>
            </Table>
          </div>

          {/* Delet modal  */}
          <Modal
            show={show4}
            onHide={handleClose4}
            backdrop="static"
            keyboard={false}
            style={{ zIndex: "99999" }}
          >
            <Modal.Header
              closeButton
            >
              <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <p className="fs-4" style={{ color: "red" }}>
                    Are you sure?
                    <br /> you want to delete this data?
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="" className="modal-close-btn"
                onClick={handleClose4}
              >
                Close
              </Button>
              <Button variant="" className="modal-add-btn"
                // onClick={DeleteCategory}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* Add Package modal */}
        <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
          <Modal.Header
            closeButton
          >
            <Modal.Title style={{ color: "black" }}>
              Add Category Query
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label className="fw-bold">Choose Service</label>
                <select className="vi_0" 
                // onChange={(e) => setCatName(e.target.value)}
                >
                  <option>Select Type</option>
                  <option>Renovation</option>
                  <option>Interior</option>
                  <option>Fabrication</option>
                  <option>Roofing Solution</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Name</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter name"
                  // onChange={(e) => setCUName(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Phone Number</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter phone number"
                  // onChange={(e) => setCUPhone(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add mail ID</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Email id"
                  // onChange={(e) => setCUEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Message</label>
                <CKEditor editor={ClassicEditor}
                  // onChange={handleChange}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                // onClick={Addcategoryenquery}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default CategoryEnquiry