import React, { useState, useEffect } from 'react'
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import "../Styles/footer.css";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaWhatsappSquare } from "react-icons/fa";
import parse from 'html-react-parser'
import axios from "axios";
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {


  // =======Footer=======//
  //integrating get  method contact us
  const [Addcontactus, setAddcontactus] = useState([]);
  const getAddcontactus = async () => {
    try {
      let res = await axios.get("https://roomyy.life/api/admin/getcontactus");
      if (res.status === 200) {
        setAddcontactus(res.data.getcontactus);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddcontactus();
  }, []);

  return (
    <div>
      <div className="footer-container">
        <Container className="p-2">
          <Row>

            <div className="col-md-2">
              <div>
                <img src="../Assets/logo.png" alt="Logo" className="logo" />
              </div>
              <div className='d-flex gap-4'>
                <div>
                  <a href='https://wa.link/wvrxiz' target="_new" style={{ color: "unset" }}>
                    <FaWhatsappSquare style={{ fontSize: "30px" }} />
                  </a>
                </div>

                <div>
                  <a href={"https://www.facebook.com/"} target="_new" style={{ color: "unset" }}>
                    <FaFacebookSquare style={{ fontSize: "30px" }} />
                  </a>
                </div>

                <div>
                  <a href={"https://www.instagram.com/"} target="_new" style={{ color: "unset" }}>
                    <FaInstagramSquare style={{ fontSize: "30px" }} />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <h3 style={{ fontWeight: "bold" }}>Quick Links</h3>
              <div>
                <div>
                  <a href='/renovation' style={{ color: "unset", textDecoration: "none" }}>
                    Renovation
                  </a>
                </div>
                <div>
                  <a href='/interior' style={{ color: "unset", textDecoration: "none" }}>
                    Interior
                  </a>
                </div>
                <div>
                  <a href='/fabrication' style={{ color: "unset", textDecoration: "none" }}>
                    Fabrication
                  </a>
                </div>
                <div>
                  <a href='/ceilingsolution' style={{ color: "unset", textDecoration: "none" }}>
                    Ceiling Solution
                  </a>
                </div>

              </div>
            </div>

            {Addcontactus?.map((val, i) => {
              return (
                <div className="col-md-2">
                  <h3 style={{ fontWeight: "bold" }}>Our Address</h3>
                  <div>
                    <p style={{ margin: "0px" }}>
                      {parse(`<div>${val?.CAddress}</div>`)}
                    </p>
                  </div>
                  <div>
                    <p style={{ margin: "0px" }}>
                      {parse(`<div>${val?.CPhone}</div>`)}

                    </p>
                    <p style={{ margin: "0px" }}>
                      {parse(`<div>${val?.CEmail}</div>`)}
                    </p>
                  </div>


                </div>
              );
            })}
          </Row>

          <Row>
            <div className="end-title mt-4 " style={{ textAlign: "center" }}>
              <p>© 2024 Roomyy. All Right Reserved.</p>
            </div>
          </Row>
        </Container>
      </div></div>
  )
}

export default Footer