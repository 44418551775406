import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image, Container } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import { IoEye } from "react-icons/io5";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import parse from 'html-react-parser';

const AdminHomePhilosophy = () => {

  //Add Modal for Slider
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setData1(item);
  };

  // Edit modal for  Slider
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (item) => {
    setHPSliderImage1(item.HPSliderImage1);
    setHPSliderImage2(item.HPSliderImage2);
    setHPSliderImage3(item.HPSliderImage3);
    setHPSliderImage4(item.HPSliderImage4);
    setMissionDesc(item.MissionDesc);
    setVisionDesc(item.VisionDesc);
    setHPTitle(item.HPTitle);
    setData1(item);
    setShow4(true);
  }

  // Delet modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setMissionDesc(data);
  };

  const handleChange2 = (e, editor) => {
    const data = editor.getData();
    setVisionDesc(data);
  };

  // Image View 
  const [View, setView] = useState({});
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ======INTEGRATION=======//

  // integrating post method
  const formdata = new FormData();
  const [HPSliderImage1, setHPSliderImage1] = useState("");
  const [HPSliderImage2, setHPSliderImage2] = useState("");
  const [HPSliderImage3, setHPSliderImage3] = useState("");
  const [HPSliderImage4, setHPSliderImage4] = useState("");
  const [HPTitle, setHPTitle] = useState("");
  const [MissionDesc, setMissionDesc] = useState("");
  const [VisionDesc, setVisionDesc] = useState("")

  const AddPhilosophyDetails = async () => {
    formdata.append("HPSliderImage1", HPSliderImage1);
    formdata.append("HPSliderImage2", HPSliderImage2);
    formdata.append("HPSliderImage3", HPSliderImage3);
    formdata.append("HPSliderImage4", HPSliderImage4);
    formdata.append("MissionDesc", MissionDesc);
    formdata.append("VisionDesc", VisionDesc);
    formdata.append("HPTitle", HPTitle);


    try {
      if (!HPTitle) {
        return alert("Please add Title");
      }
      if (!HPSliderImage1) {
        return alert("Please add Image");
      }
      if (!HPSliderImage2) {
        return alert("Please add Image");
      }
      if (!HPSliderImage3) {
        return alert("Please add Image");
      }
      if (!HPSliderImage4) {
        return alert("Please add Image");
      }
      if (!MissionDesc) {
        return alert("Please add Title");
      }
      if (!VisionDesc) {
        return alert("Please add Description");
      }
      const config = {
        url: "/admin/philosophy",
        method: "post",
        baseURL: "https://roomyy.life/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddPhilosophy();
        handleClose3();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddPhilosophy, setAddPhilosophy] = useState([]);
  const getAddPhilosophy = async () => {
    try {
      let res = await axios.get(
        "https://roomyy.life/api/admin/getphilosophy"
      );
      if (res.status === 200) {
        setAddPhilosophy(res.data.getphilosophy);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const DeletePhilosophy = async () => {
    try {
      const config = {
        url: "admin/Deletephilosophy/" + Data,
        method: "delete",
        baseURL: "https://roomyy.life/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddPhilosophy();
          handleClose5();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const [show3, setShow3] = useState(false);

  const EditPhilosophy = async (e) => {
    e.preventDefault();
    formdata.append("HPSliderImage1", HPSliderImage1);
    formdata.append("HPSliderImage2", HPSliderImage2);
    formdata.append("HPSliderImage3", HPSliderImage3);
    formdata.append("HPSliderImage4", HPSliderImage4);
    formdata.append("MissionDesc", MissionDesc);
    formdata.append("VisionDesc", VisionDesc);
    formdata.append("HPTitle", HPTitle);


    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "admin/editphilosophy",
        method: "put",
        baseURL: "https://roomyy.life/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose4();
          getAddPhilosophy();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };
  useEffect(() => {
    getAddPhilosophy();
  }, []);
  console.log(AddPhilosophy);

  // pagination
  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 5;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  const records = AddPhilosophy.slice(firstIndex, lastIndex);
  const npages = Math.ceil(AddPhilosophy.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }

  return (
    <div>
      <div className="col-lg-4 d-flex justify-content-center">
        <div class="input-group ">
          <span class="input-group-text" id="basic-addon1">
            <BsSearch />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
          />
        </div>
      </div>
      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Home Philosophy</h2>
          <div className="d-flex gap-3">
            <button className="admin-add-btn" onClick={handleShow3}>
              Add Home Philosophy
            </button>
          </div>

        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Title</th>
                <th>Slider Images</th>
                <th>Mission Description</th>
                <th>Vision Description</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {records?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1 + firstIndex}</td>
                    <td style={{ paddingTop: "20px" }}>{parse(`<div>${item.HPTitle}</div>`)}</td>
                    <td>
                      <IoEye
                        style={{ fontSize: "25px", color: "green" }}
                        onClick={() => {
                          handleShow6();
                          setView(item);
                        }}
                      />
                    </td>
                    <td style={{ paddingTop: "20px" }}>{parse(`<div>${item.MissionDesc}</div>`)}</td>
                    <td style={{ paddingTop: "20px" }}>{parse(`<div>${item.VisionDesc}</div>`)}</td>
                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className="text-success"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow4(item);
                              setData1(item);
                            }
                            }
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className="text-danger"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow5();
                              setData(item?._id);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Package modal for Slider */}
        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header
            closeButton
          >
            <Modal.Title>Add Home Philosophy</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Title</label>
              <input
                type="text"
                className="vi_0"
                placeholder="Enter Banner Title"
                onChange={(e) => setHPTitle(e.target.value)}
              />
            </div>
          </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Renovation Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setHPSliderImage1(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Interior Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setHPSliderImage2(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Fabrication Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setHPSliderImage3(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Ceiling Solution Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setHPSliderImage4(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Mission Description</label>
                <CKEditor editor={ClassicEditor}
                  onChange={handleChange1}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Vision Description</label>
                <CKEditor editor={ClassicEditor}
                  onChange={handleChange2}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={AddPhilosophyDetails}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title style={{ color: "black" }}>Edit Home Philosophy</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Title</label>
              <input
                type="text"
                className="vi_0"
                placeholder="Enter Banner Title"
                value={HPTitle}
                onChange={(e) => setHPTitle(e.target.value)}
              />
            </div>
          </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Renovation Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setHPSliderImage1(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Interior Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setHPSliderImage2(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Fabrication Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setHPSliderImage3(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Ceiling Solution Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setHPSliderImage4(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Mission Description</label>
                <CKEditor editor={ClassicEditor}
                  data={MissionDesc}
                  onChange={handleChange1}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Vision Description</label>
                <CKEditor editor={ClassicEditor}
                  data={VisionDesc}
                  onChange={handleChange2}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button variant="" className="modal-add-btn"
              onClick={EditPhilosophy}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delet Package modal for Slider */}
        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={DeletePhilosophy}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Slider Image modal  */}
        <Modal
          show={show6}
          onHide={handleClose6}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title style={{ color: "black" }}>Philosophy Slider Images</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className="row">
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/HomePhilosophy/${View?.HPSliderImage1}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/HomePhilosophy/${View?.HPSliderImage2}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/HomePhilosophy/${View?.HPSliderImage3}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/HomePhilosophy/${View?.HPSliderImage4}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
              </div>
            </Container>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose6}
            >
              Close
            </Button>

          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default AdminHomePhilosophy