import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import parse from 'html-react-parser';

const FindBetterInterior = () => {
 // CKEditor 
 const handleChange = (e, editor) => {
  const data = editor.getData();
  setFindBetterDesc(data);
};

//Add Modal for Slider
const handleClose3 = () => setShow3(false);
const handleShow3 = (item) => {
  setShow3(true);
  setData1(item);
};

// Edit modal for  Slider
const [show4, setShow4] = useState(false);
const handleClose4 = () => setShow4(false);
const handleShow4 = (item) => {
  setFindBetterTitle(item.FindBetterTitle);
  setFindBetterDesc(item.FindBetterDesc);
  setFindBetterImage1(item.FindBetterImage1);
  setFindBetterImage2(item.FindBetterImage2);
  setFindBetterImage3(item.FindBetterImage3);
  setData1(item);
  setShow4(true);
}

// Delet modal for  Banner
const [show5, setShow5] = useState();
const handleClose5 = () => setShow5(false);
const handleShow5 = () => setShow5(true);

// Image View 
const [View, setView] = useState({});
const [show6, setShow6] = useState(false);
const handleClose6 = () => setShow6(false);
const handleShow6 = () => setShow6(true);


// ======INTEGRATION=======//

// integrating post method
const formdata = new FormData();
const [FindBetterTitle, setFindBetterTitle] = useState("");
const [FindBetterDesc, setFindBetterDesc] = useState("");
const [FindBetterImage1, setFindBetterImage1] = useState("");
const [FindBetterImage2, setFindBetterImage2] = useState("");
const [FindBetterImage3, setFindBetterImage3] = useState("");

const AddFindBetterDetails = async () => {
  formdata.append("FindBetterTitle", FindBetterTitle);
  formdata.append("FindBetterDesc", FindBetterDesc);
  formdata.append("FindBetterImage1", FindBetterImage1);
  formdata.append("FindBetterImage2", FindBetterImage2);
  formdata.append("FindBetterImage3", FindBetterImage3);
  try {
    if (!FindBetterTitle) {
      return alert("Please add Title");
    }
    if (!FindBetterDesc) {
      return alert("Please add description");
    }
  
    if (!FindBetterImage1) {
      return alert("Please add Image");
    }
    if (!FindBetterImage2) {
      return alert("Please add Image");
    }
    if (!FindBetterImage3) {
      return alert("Please add Image");
    }
    const config = {
      url: "/admin/findbetter",
      method: "post",
      baseURL: "https://roomyy.life/api",
      header: { "content-type": "multipart/form-data" },
      data: formdata,
    };
    let res = await axios(config);
    if (res.status === 200) {
      alert(res.data.success);
      getAddFindBetter();
      handleClose3();
    }
  } catch (error) {
    console.log(error);
    alert(error.response.data.msg);
  }
};
//integrating get  method
const [AddFindBetter, setAddFindBetter] = useState([]);
const getAddFindBetter = async () => {
  try {
    let res = await axios.get(
      "https://roomyy.life/api/admin/getfindbetter"
    );
    if (res.status === 200) {
      setAddFindBetter(res.data.getfindbetter);
    }
  } catch (error) {
    console.log(error);
  }
};

//delete method
const [Data, setData] = useState("");
const DeleteFindBetter = async () => {
  try {
    const config = {
      url: "admin/Deletefindbetter/" + Data,
      method: "delete",
      baseURL: "https://roomyy.life/api/",
      header: { "content-type": "application/json" },
    };
    await axios(config).then((res) => {
      if (res.status === 200) {
        alert("Successfully Delete");
        getAddFindBetter();
        handleClose5();
      }
    });
  } catch (error) {
    console.log(error);
    alert(error.response.data.msg);
  }
};

//update method
const [Data1, setData1] = useState("");
const [show3, setShow3] = useState(false);

const EditFindBetter = async (e) => {
  e.preventDefault();
  formdata.append("FindBetterTitle", FindBetterTitle);
  formdata.append("FindBetterDesc", FindBetterDesc);
  formdata.append("FindBetterImage1", FindBetterImage1);
  formdata.append("FindBetterImage2", FindBetterImage2);
  formdata.append("FindBetterImage3", FindBetterImage3);

  formdata.append("id", Data1?._id);
  try {
    const config = {
      url: "admin/editfindbetter",
      method: "put",
      baseURL: "https://roomyy.life/api/",
      header: { "content-type": "multipart/form-data" },
      data: formdata,
    };
    await axios(config).then((res) => {
      if (res.status === 200) {
        alert("successfully Update");
        handleClose4();
        getAddFindBetter();
      }
    });
  } catch (error) {
    console.log(error);
    alert(error.response.data.error);
  }
};
// };
useEffect(() => {
  getAddFindBetter();
}, []);
console.log(AddFindBetter);

// pagination
const [currenpage, setCurrentpage] = useState(1);
const recordsperpage = 5;
const lastIndex = currenpage * recordsperpage;
const firstIndex = lastIndex - recordsperpage;
const records = AddFindBetter.slice(firstIndex, lastIndex);
const npages = Math.ceil(AddFindBetter.length / recordsperpage);
const numbers = [...Array(npages + 1).keys()].slice(1);

function changePage(id) {
  setCurrentpage(id);
}

function prevpage() {
  if (currenpage !== firstIndex) {
    setCurrentpage(currenpage - 1);
  }
}

function nextpage() {
  if (currenpage !== lastIndex) {
    setCurrentpage(currenpage + 1);
  }
}

  return (
    <div>
      <div className="col-lg-4 d-flex justify-content-center">
        <div class="input-group ">
          <span class="input-group-text" id="basic-addon1">
            <BsSearch />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="header-c ">Find Better</h2>
        <div className="d-flex gap-3">
          <button className="admin-add-btn" onClick={handleShow3}>
            Add Find Better
          </button>

        </div>

      </div>

      <div className="mb-3">
        <Table
          responsive
          bordered
          style={{ width: "-webkit-fill-available" }}
        >
          <thead>
            <tr>
              <th>S.No</th>
              <th>Title</th>
              <th>Description</th>
              <th>Image</th>
              <th>Image</th>
              <th>Image</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
          {records?.map((item, i) => {
              return (

                <tr key={i}>
                  <td>{i + 1 + firstIndex}</td>
                  <td style={{ paddingTop: "20px" }}>{item.FindBetterTitle}</td>
                  <td style={{ paddingTop: "20px" }}>{parse(`<div>${item.FindBetterDesc}</div>`)}</td>
                  <td>
                    <Image
                      src={`https://roomyy.life/FindBetter/${item?.FindBetterImage1}`}
                      alt="pic"
                      style={{ width: "75px", height: "75px" }}
                    />
                  </td>
                  <td>
                    <Image
                      src={`https://roomyy.life/FindBetter/${item?.FindBetterImage2}`}
                      alt="pic"
                      style={{ width: "75px", height: "75px" }}
                    />
                  </td>
                  <td>
                    <Image
                      src={`https://roomyy.life/FindBetter/${item?.FindBetterImage3}`}
                      alt="pic"
                      style={{ width: "75px", height: "75px" }}
                    />
                  </td>
                  <td>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <BiSolidEdit
                          className="text-success"
                          style={{ cursor: "pointer", fontSize: "20px" }}
                          onClick={() => {
                            handleShow4(item);
                            setData1(item);
                          }
                          }
                        />{" "}
                      </div>
                      <div>
                        <AiFillDelete
                          className="text-danger"
                          style={{ cursor: "pointer", fontSize: "20px" }}
                          onClick={() => {
                            handleShow5();
                            setData(item?._id);
                          }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      {/* Add Package modal */}
      <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
        <Modal.Header
          closeButton

        >
          <Modal.Title style={{ color: "black" }}>Add Find Better</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Title</label>
              <input
                type="text"
                className="vi_0"
                placeholder="Enter Banner Title"
                onChange={(e) => setFindBetterTitle(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Description</label>
              <CKEditor editor={ClassicEditor}
              onChange={handleChange}
              />
            </div>
          </div>


          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setFindBetterImage1(e.target.files[0])}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setFindBetterImage2(e.target.files[0])}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setFindBetterImage3(e.target.files[0])}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <Button
              className="mx-2 modal-close-btn"
              variant=""
              onClick={handleClose3}
            >
              Close
            </Button>
            <Button
              className="mx-2 modal-add-btn"
              variant=""
            onClick={AddFindBetterDetails}
            >
              Add
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Edit Package modal */}
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
        style={{ zIndex: "99999" }}
      >
        <Modal.Header
          closeButton
        >
          <Modal.Title style={{ color: "black" }}>Edit Find Better</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Title</label>
              <input
                type="text"
                className="vi_0"
                placeholder="Enter Banner Title"
                value={FindBetterTitle}
                onChange={(e) => setFindBetterTitle(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Description</label>
              <CKEditor editor={ClassicEditor}
              data={FindBetterDesc}
              onChange={handleChange}
              />
            </div>
          </div>


          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setFindBetterImage1(e.target.files[0])}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setFindBetterImage2(e.target.files[0])}
              />
            </div>
          </div>

          <div className="row">
            <div className="do-sear mt-2">
              <label>Add Image</label>
              <input
                type="file"
                name=""
                id=""
                className="vi_0"
                onChange={(e) => setFindBetterImage3(e.target.files[0])}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            className="modal-close-btn"
            onClick={handleClose4}
          >
            Close
          </Button>
          <Button variant="" className="modal-add-btn"
            onClick={EditFindBetter}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delet modal  */}
      <Modal
        show={show5}
        onHide={handleClose5}
        backdrop="static"
        keyboard={false}
        style={{ zIndex: "99999" }}
      >
        <Modal.Header
          closeButton
        >
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <p className="fs-4" style={{ color: "red" }}>
                Are you sure?
                <br /> you want to delete this data?
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            className="modal-close-btn"
            onClick={handleClose5}
          >
            Close
          </Button>
          <Button
            variant=""
            className="modal-add-btn"
            onClick={DeleteFindBetter}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default FindBetterInterior