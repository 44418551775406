import '../Styles/home.css'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ScrollTrigger from 'react-scroll-trigger';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FaGlobeAfrica } from "react-icons/fa";
import { FaGoogleDrive } from "react-icons/fa";
import { VscRefresh } from "react-icons/vsc";
import { LuArrowUpLeftFromCircle } from "react-icons/lu";
import Carousel from "react-bootstrap/Carousel";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Container } from 'react-bootstrap';
import Aos from "aos";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Philosophy from './Philosophy';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import video from "../../src/team.mp4"
import parse from 'html-react-parser'
import axios from "axios";


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
    };



    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const Home = () => {
    const [counterOn, setCounterOn] = useState(false);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    useEffect(() => {
        Aos.init();
        window.scroll(0, 0);
    });


    const options1 = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
    };

    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 4,
            },
        },
    };

    // ========SERVICES=========//

    //integrating get  method
    const [AddServices, setAddServices] = useState([]);
    const getAddServices = async () => {
        try {
            let res = await axios.get(
                "https://roomyy.life/api/admin/gethomeservice"
            );
            if (res.status === 200) {
                setAddServices(res.data.gethomeservice);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAddServices();
    }, []);


    // =======PHILOSOPHY=======//
    //integrating get  method
    const [AddPhilosophy, setAddPhilosophy] = useState([]);
    const getAddPhilosophy = async () => {
        try {
            let res = await axios.get(
                "https://roomyy.life/api/admin/getphilosophy"
            );
            if (res.status === 200) {
                setAddPhilosophy(res.data.getphilosophy);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getAddPhilosophy();
    }, []);


    //   =========CATEGORY========//
    //integrating get  method
    const [AddCategory, setAddCategory] = useState([]);
    const getAddCategory = async () => {
        try {
            let res = await axios.get(
                "https://roomyy.life/api/admin/getcategory"
            );
            if (res.status === 200) {
                setAddCategory(res.data.getcategory);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getAddCategory();
    }, []);


    // ======WHY CHOOSE US=======//
    //integrating get  method
    const [AddWhychooseus, setAddWhychooseus] = useState([]);
    const getAddWhychooseus = async () => {
        try {
            let res = await axios.get(
                "https://roomyy.life/api/admin/getwhychoose"
            );
            if (res.status === 200) {
                setAddWhychooseus(res.data.getwhychoose);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getAddWhychooseus();
    }, []);

    return (
        <div className='resp'>

            {AddServices?.map((val, i) => {
                return (
                    <Container data-aos="fade-up"
                        data-aos-delay="50"
                        data-aos-duration="3000" >
                        <div className="elev" >
                            <div className="line">
                                {parse(`<div>${val?.HSTitle}</div>`)}
                            </div>
                        </div>
                        <hr className='hr' />
                        <div >
                            <div className="mb-3">
                                <h3>
                                    {parse(`<div>${val?.HSTagline}</div>`)}
                                </h3>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-4 mb-2">
                                    <h6>
                                        {parse(`<div>${val?.HSDesc1}</div>`)}
                                    </h6>
                                </div>
                                <div className="col-md-4 mb-2">
                                    <h6>
                                        {parse(`<div>${val?.HSDesc2}</div>`)}
                                    </h6>
                                </div>
                                <div className="col-md-4 mb-2">
                                    <h6>
                                        {parse(`<div>${val?.HSDesc3}</div>`)}
                                    </h6>
                                </div>

                            </div>
                        </div>
                    </Container>
                );
            })}

            {AddServices?.map((val, i) => {
                return (
                    <div className=" mt-2">
                        <img src={`https://roomyy.life/HomeServices/${val?.HSImage}`}
                            alt=''
                            className='movingLine'
                        />
                        <div className="backgroundImg">
                            <marquee behavior="" direction="" speed='6s' style={{ fontSize: "50px", position: "relative", top: "-230px", fontWeight: "bold", color: "white" }}>
                                {parse(`<div>${val?.HSText}</div>`)}
                            </marquee>
                        </div>
                    </div>
                );
            })}

            {/* --------------Philoophy */}
            {AddPhilosophy?.map((val, i) => {
                return (
                    <Container className='mb-4' data-aos="fade-up"
                        data-aos-delay="50"
                        data-aos-duration="3000">

                        <div className="elev" >
                            <div className="line">
                                {parse(`<div>${val?.HPTitle}</div>`)}
                            </div>
                        </div>
                        <hr className='hr' />
                        <div>
                            <Philosophy />
                        </div>
                    </Container>
                );
            })}
            {/* ------------------CATEGORY ------------------------ */}


            <Container>
                <div className='row mt-5'>
                    {AddCategory?.map((val, i) => {
                        return (
                            <div className='col-md-3'>

                                <Card className='home-row-cards'>
                                    <Card.Header>
                                        <div style={{ textAlign: "center" }}>
                                            <FaGlobeAfrica style={{ fontSize: "50px", color: "rgb(7, 56, 66)" }} />
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Title>
                                            {parse(`<div>${val?.HCTitle}</div>`)}
                                        </Card.Title>
                                        <Card.Text>
                                            <p style={{ color: "gray" }}>
                                                {parse(`<div>${val?.HCDesc}</div>`)}
                                            </p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        );
                    })}

                </div>
            </Container>

            {/* -------------------------------Category Curosal------------------------------- */}
            {/* <div className="OwelCurosal mt-10 pt-10" data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="3000">
                <OwlCarousel
                    loop
                    margin={5}
                    nav
                    {...options}
                >

                    <div class="item">
                        <div class="card">
                            <div class="image-box">
                                <img
                                    src="../Assets/i1.jpg"
                                    alt=""
                                    className="about-slider-img"
                                />
                            </div>

                        </div>
                    </div>

                    <div class="item">
                        <div class="card" >
                            <div class="image-box">
                                <img
                                    src="../Assets/i2.jpg"
                                    alt=""
                                    className="about-slider-img"
                                />
                            </div>

                        </div>
                    </div>

                    <div class="item">
                        <div class="card">
                            <div class="image-box">
                                <img
                                    src="../Assets/i3.jpg"
                                    alt=""
                                    className="about-slider-img"
                                />
                            </div>

                        </div>
                    </div>

                    <div class="item">
                        <div class="card">
                            <div class="image-box">
                                <img
                                    src="../Assets/i1.jpg"
                                    alt=""
                                    className="about-slider-img"
                                />
                            </div>

                        </div>
                    </div>
                    <div class="item">
                        <div class="card">
                            <div class="image-box">
                                <img
                                    src="../Assets/i2.jpg"
                                    alt=""
                                    className="about-slider-img"
                                />
                            </div>

                        </div>
                    </div>
                    <div class="item">
                        <div class="card">
                            <div class="image-box">
                                <img
                                    src="../Assets/i3.jpg"
                                    alt=""
                                    className="about-slider-img"
                                />
                            </div>

                        </div>
                    </div>

                </OwlCarousel>
            </div> */}

            {/* ------------------------WHY CHOOSE US-------------------------- */}

            {AddWhychooseus?.map((val, i) => {
                return (
                    <Container data-aos="fade-up"

                        data-aos-delay="50"
                        data-aos-duration="3000">
                        <div className="elev" >
                            <div className="line">
                                {parse(`<div>${val?.HWCUTitle}</div>`)}
                            </div>
                        </div>
                        <hr />
                        <h4>
                            {parse(`<div>${val?.HWCUTagline}</div>`)}
                        </h4>
                        <div className='row mt-3'>
                            <div className='col-md-8'>
                                <div>
                                    <object
                                        data={`https://roomyy.life/HomeWhyChooseus/${val?.HVideo}`}
                                        type="video/mp4"
                                        autoPlay loop muted 
                                        style={{ height: "425px", width: "100%" }}
                                    >
                                    </object>
                                    {/* <video src={video} autoPlay loop muted className='home-banner' style={{ height: "auto", width: "100%" }} /> */}
                                    {/* <video src={video} autoPlay loop muted className='home-banner' style={{ height: "auto", width: "100%" }} /> */}
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div style={{ boxShadow: "0px 1px 3px 2px gray", padding: "20px" }} >
                                    <div data-aos="fade-up"
                                        data-aos-delay="50"
                                        data-aos-duration="3000">
                                        <div className='d-flex gap-2'>
                                            {/* <b>*</b> */}
                                            <p>
                                                {parse(`<div>${val?.HWCUDesc}</div>`)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                );
            })}

            <br />
        </div>
    )
}

export default Home
