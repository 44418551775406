import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image, Container } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import ImageUploading from "react-images-uploading";
import axios from "axios";
import { IoEye } from "react-icons/io5";

const AdminGallery = () => {


  // Image View 
  const [View, setView] = useState({});
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  //  More Image Upload 
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  //Add Modal for Slider
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setData1(item);
  };


  // Edit modal for  Slider
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (item) => {
    setGalleryBannerImage(item.GalleryBannerImage);
    setGalleryBannerCaption(item.GalleryBannerCaption);
    setGalleryImgs1(item.GalleryImgs1);
    setGalleryImgs2(item.GalleryImgs2);
    setGalleryImgs3(item.GalleryImgs3);
    setGalleryImgs4(item.GalleryImgs4);
    setGalleryImgs5(item.GalleryImgs5);
    setGalleryImgs6(item.GalleryImgs6);
    setGalleryImgs7(item.GalleryImgs7);
    setGalleryImgs8(item.GalleryImgs8);
    setGalleryImgs9(item.GalleryImgs9);
    setGalleryImgs10(item.GalleryImgs10);

    setData1(item);
    setShow4(true);
  }

  // Delet modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);


  // ======INTEGRATION=======//

  // integrating post method
  const formdata = new FormData();
  const [GalleryBannerImage, setGalleryBannerImage] = useState("");
  const [GalleryBannerCaption, setGalleryBannerCaption] = useState("");
  const [GalleryImgs1, setGalleryImgs1] = useState("");
  const [GalleryImgs2, setGalleryImgs2] = useState("");
  const [GalleryImgs3, setGalleryImgs3] = useState("");
  const [GalleryImgs4, setGalleryImgs4] = useState("");
  const [GalleryImgs5, setGalleryImgs5] = useState("");
  const [GalleryImgs6, setGalleryImgs6] = useState("");
  const [GalleryImgs7, setGalleryImgs7] = useState("");
  const [GalleryImgs8, setGalleryImgs8] = useState("");
  const [GalleryImgs9, setGalleryImgs9] = useState("");
  const [GalleryImgs10, setGalleryImgs10] = useState("");

  const AddGalleryDetails = async () => {
    formdata.append("GalleryBannerImage", GalleryBannerImage);
    formdata.append("GalleryBannerCaption", GalleryBannerCaption);
    formdata.append("GalleryImgs1", GalleryImgs1);
    formdata.append("GalleryImgs2", GalleryImgs2);
    formdata.append("GalleryImgs3", GalleryImgs3);
    formdata.append("GalleryImgs4", GalleryImgs4);
    formdata.append("GalleryImgs5", GalleryImgs5);
    formdata.append("GalleryImgs6", GalleryImgs6);
    formdata.append("GalleryImgs7", GalleryImgs7);
    formdata.append("GalleryImgs8", GalleryImgs8);
    formdata.append("GalleryImgs9", GalleryImgs9);
    formdata.append("GalleryImgs10", GalleryImgs10);

    try {
      if (!GalleryBannerImage) {
        return alert("Please add Image");
      }
      if (!GalleryBannerCaption) {
        return alert("Please add Caption");
      }
      // if (!GalleryImgs1) {
      //   return alert("Please add Image");
      // }
      const config = {
        url: "/admin/gallery",
        method: "post",
        baseURL: "https://roomyy.life/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddGallery();
        handleClose3();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddGallery, setAddGallery] = useState([]);
  const getAddGallery = async () => {
    try {
      let res = await axios.get(
        "https://roomyy.life/api/admin/getgallery"
      );
      if (res.status === 200) {
        setAddGallery(res.data.getgallery);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const DeleteGallery = async () => {
    try {
      const config = {
        url: "admin/Deletegallery/" + Data,
        method: "delete",
        baseURL: "https://roomyy.life/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddGallery();
          handleClose5();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const [show3, setShow3] = useState(false);

  const EditGallery = async (e) => {
    e.preventDefault();
    formdata.append("GalleryBannerImage", GalleryBannerImage);
    formdata.append("GalleryBannerCaption", GalleryBannerCaption);
    formdata.append("GalleryImgs1", GalleryImgs1);
    formdata.append("GalleryImgs2", GalleryImgs2);
    formdata.append("GalleryImgs3", GalleryImgs3);
    formdata.append("GalleryImgs4", GalleryImgs4);
    formdata.append("GalleryImgs5", GalleryImgs5);
    formdata.append("GalleryImgs6", GalleryImgs6);
    formdata.append("GalleryImgs7", GalleryImgs7);
    formdata.append("GalleryImgs8", GalleryImgs8);
    formdata.append("GalleryImgs9", GalleryImgs9);
    formdata.append("GalleryImgs10", GalleryImgs10);

    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "admin/editgallery",
        method: "put",
        baseURL: "https://roomyy.life/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose4();
          getAddGallery();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };
  useEffect(() => {
    getAddGallery();
  }, []);
  console.log(AddGallery);

  // pagination
  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 5;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  const records = AddGallery.slice(firstIndex, lastIndex);
  const npages = Math.ceil(AddGallery.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }

  return (
    <div>
      <div className="col-lg-4 d-flex justify-content-center">
        <div class="input-group ">
          <span class="input-group-text" id="basic-addon1">
            <BsSearch />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
          />
        </div>
      </div>
      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Gallery</h2>
          <button className="admin-add-btn"
            onClick={handleShow3}
          >
            Add Gallery
          </button>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Banner Image</th>
                <th>Banner Title</th>
                <th>Gallery Images</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>

              {records?.map((item, i) => {
                return (

                  <tr key={i}>
                    <td>
                      <Image
                        src={`https://roomyy.life/Gallery/${item?.GalleryBannerImage}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />
                    </td>
                    <td style={{ paddingTop: "20px" }}>{item.GalleryBannerCaption}</td>
                    <td>
                      <IoEye
                        style={{ fontSize: "25px", color: "green" }}
                        onClick={() => {
                          handleShow6();
                          setView(item);
                        }}
                      />
                    </td>
                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className="text-success"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow4(item);
                              setData1(item);
                            }
                            }
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className="text-danger"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow5();
                              setData(item?._id);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Package modal */}
        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header
            closeButton

          >
            <Modal.Title style={{ color: "black" }}>Add Gallery</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Banner Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setGalleryBannerImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Banner Caption</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  onChange={(e) => setGalleryBannerCaption(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  className="vi_0"
                  onChange={(e) => setGalleryImgs1(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  className="vi_0"
                  onChange={(e) => setGalleryImgs2(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  multiple
                  className="vi_0"
                  onChange={(e) => setGalleryImgs3(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  multiple
                  className="vi_0"
                  onChange={(e) => setGalleryImgs4(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  multiple
                  className="vi_0"
                  onChange={(e) => setGalleryImgs5(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  multiple
                  className="vi_0"
                  onChange={(e) => setGalleryImgs6(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  multiple
                  className="vi_0"
                  onChange={(e) => setGalleryImgs7(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  multiple
                  className="vi_0"
                  onChange={(e) => setGalleryImgs8(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  multiple
                  className="vi_0"
                  onChange={(e) => setGalleryImgs9(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  multiple
                  className="vi_0"
                  onChange={(e) => setGalleryImgs10(e.target.files[0])}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={AddGalleryDetails}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title style={{ color: "black" }}>Edit Gallery</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Banner Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setGalleryBannerImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Banner Caption</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  value={GalleryBannerCaption}
                  onChange={(e) => setGalleryBannerCaption(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  className="vi_0"
                  onChange={(e) => setGalleryImgs1(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  className="vi_0"
                  onChange={(e) => setGalleryImgs2(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  multiple
                  className="vi_0"
                  onChange={(e) => setGalleryImgs3(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  multiple
                  className="vi_0"
                  onChange={(e) => setGalleryImgs4(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  multiple
                  className="vi_0"
                  onChange={(e) => setGalleryImgs5(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  multiple
                  className="vi_0"
                  onChange={(e) => setGalleryImgs6(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  multiple
                  className="vi_0"
                  onChange={(e) => setGalleryImgs7(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  multiple
                  className="vi_0"
                  onChange={(e) => setGalleryImgs8(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  multiple
                  className="vi_0"
                  onChange={(e) => setGalleryImgs9(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Image</label>
                <input
                  type="file"
                  multiple
                  className="vi_0"
                  onChange={(e) => setGalleryImgs10(e.target.files[0])}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button variant="" className="modal-add-btn"
              onClick={EditGallery}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delet modal  */}
        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={DeleteGallery}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

          {/* Slider Image modal  */}
          <Modal
                    show={show6}
                    onHide={handleClose6}
                    backdrop="static"
                    keyboard={false}
                    style={{ zIndex: "99999" }}
                >
                    <Modal.Header
                        closeButton
                    >
                        <Modal.Title style={{ color: "black" }}>About Showcase Images</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <Image
                                        src={`https://roomyy.life/Gallery/${View?.GalleryImgs1}`}
                                        alt="pic"
                                        style={{ width: "100%", height: "200px" }}
                                    />
                                </div>
                               
                                <div className="col-md-6 mb-2">
                                    <Image
                                        src={`https://roomyy.life/Gallery/${View?.GalleryImgs2}`}
                                        alt="pic"
                                        style={{ width: "100%", height: "200px" }}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Image
                                        src={`https://roomyy.life/Gallery/${View?.GalleryImgs3}`}
                                        alt="pic"
                                        style={{ width: "100%", height: "200px" }}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Image
                                        src={`https://roomyy.life/Gallery/${View?.GalleryImgs4}`}
                                        alt="pic"
                                        style={{ width: "100%", height: "200px" }}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Image
                                        src={`https://roomyy.life/Gallery/${View?.GalleryImgs5}`}
                                        alt="pic"
                                        style={{ width: "100%", height: "200px" }}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Image
                                        src={`https://roomyy.life/Gallery/${View?.GalleryImgs6}`}
                                        alt="pic"
                                        style={{ width: "100%", height: "200px" }}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Image
                                        src={`https://roomyy.life/Gallery/${View?.GalleryImgs7}`}
                                        alt="pic"
                                        style={{ width: "100%", height: "200px" }}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Image
                                        src={`https://roomyy.life/Gallery/${View?.GalleryImgs8}`}
                                        alt="pic"
                                        style={{ width: "100%", height: "200px" }}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Image
                                        src={`https://roomyy.life/Gallery/${View?.GalleryImgs9}`}
                                        alt="pic"
                                        style={{ width: "100%", height: "200px" }}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Image
                                        src={`https://roomyy.life/Gallery/${View?.GalleryImgs10}`}
                                        alt="pic"
                                        style={{ width: "100%", height: "200px" }}
                                    />
                                </div>
                            </div>
                        </Container>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant=""
                            className="modal-close-btn"
                            onClick={handleClose6}
                        >
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
      </div>


    
    </div>
  )
}

export default AdminGallery