import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import parse from 'html-react-parser';

const AboutOurServices = () => {

  //Add Modal for Slider
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setData1(item);
  };

  // Edit modal for  Slider
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (item) => {
    setAboutServiceImg(item.AboutServiceImg);
    setAboutServiceHeading(item.AboutServiceHeading);
    setAboutServiceTitle(item.AboutServiceTitle);
    setAboutServiceDesc(item.AboutServiceDesc);
    setAboutServiceTitle1(item.AboutServiceTitle1);
    setAboutServiceDesc1(item.AboutServiceDesc1);
    setData1(item);
    setShow4(true);
  }

  // Delet modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setAboutServiceDesc(data);
  };
  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setAboutServiceDesc1(data);
  };

  // Image View 
  const [View, setView] = useState({});
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ======INTEGRATION=======//

  // integrating post method
  const formdata = new FormData();
  const [AboutServiceHeading, setAboutServiceHeading] = useState("");
  const [AboutServiceTitle, setAboutServiceTitle] = useState("");
  const [AboutServiceDesc, setAboutServiceDesc] = useState("");
  const [AboutServiceImg, setAboutServiceImg] = useState("");
  const [AboutServiceTitle1, setAboutServiceTitle1] = useState("");
  const [AboutServiceDesc1, setAboutServiceDesc1] = useState("");


  const AddAboutServiceDetails = async () => {
    formdata.append("AboutServiceHeading", AboutServiceHeading);
    formdata.append("AboutServiceTitle", AboutServiceTitle);
    formdata.append("AboutServiceDesc", AboutServiceDesc);
    formdata.append("AboutServiceImg", AboutServiceImg);
    formdata.append("AboutServiceTitle1", AboutServiceTitle1);
    formdata.append("AboutServiceDesc1", AboutServiceDesc1);

    try {

      if (!AboutServiceHeading) {
        // return alert("Please add Heading");
      }
      if (!AboutServiceTitle) {
        return alert("Please add Title");
      }
      if (!AboutServiceDesc) {
        return alert("Please add Description");
      }
      if (!AboutServiceImg) {
        return alert("Please add Image");
      }
      if (!AboutServiceTitle1) {
        return alert("Please add Title");
      }
      if (!AboutServiceDesc1) {
        return alert("Please add Description");
      }
      const config = {
        url: "/admin/aboutservices",
        method: "post",
        baseURL: "https://roomyy.life/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddAboutService();
        handleClose3();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddAboutService, setAddAboutService] = useState([]);
  const getAddAboutService = async () => {
    try {
      let res = await axios.get(
        "https://roomyy.life/api/admin/getaboutservice"
      );
      if (res.status === 200) {
        setAddAboutService(res.data.getaboutservice);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const DeleteAboutService = async () => {
    try {
      const config = {
        url: "admin/Deleteaboutservice/" + Data,
        method: "delete",
        baseURL: "https://roomyy.life/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddAboutService();
          handleClose5();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const [show3, setShow3] = useState(false);

  const EditAboutService = async (e) => {
    e.preventDefault();
    formdata.append("AboutServiceHeading", AboutServiceHeading);
    formdata.append("AboutServiceTitle", AboutServiceTitle);
    formdata.append("AboutServiceDesc", AboutServiceDesc);
    formdata.append("AboutServiceImg", AboutServiceImg);
    formdata.append("AboutServiceTitle1", AboutServiceTitle1);
    formdata.append("AboutServiceDesc1", AboutServiceDesc1);

    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "admin/editaboutservice",
        method: "put",
        baseURL: "https://roomyy.life/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose4();
          getAddAboutService();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };
  useEffect(() => {
    getAddAboutService();
  }, []);
  console.log(AddAboutService);

  // pagination
  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 5;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  const records = AddAboutService.slice(firstIndex, lastIndex);
  const npages = Math.ceil(AddAboutService.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }



  return (
    <div>
      <div className="col-lg-4 d-flex justify-content-center">
        <div class="input-group ">
          <span class="input-group-text" id="basic-addon1">
            <BsSearch />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
          />
        </div>
      </div>
      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Our Services</h2>
          <button className="admin-add-btn"
            onClick={handleShow3}
          >
            Add Our Services
          </button>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>Heading</th>

                <th>Title</th>
                <th>Description</th>

                <th>Image</th>

                <th>Title</th>
                <th>Description</th>

                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {records?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1 + firstIndex}</td>
                    <td style={{ paddingTop: "20px" }}>{item.AboutServiceHeading}</td>
                    <td style={{ paddingTop: "20px" }}>{item.AboutServiceTitle}</td>
                    <td style={{ paddingTop: "20px" }}>{parse(`<div>${item.AboutServiceDesc}</div>`)}</td>
                    <td>
                    <Image
                        src={`https://roomyy.life/AboutService/${item?.AboutServiceImg}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />
                    </td>
                    <td style={{ paddingTop: "20px" }}>{item.AboutServiceTitle1}</td>
                    <td style={{ paddingTop: "20px" }}>{parse(`<div>${item.AboutServiceDesc1}</div>`)}</td>

                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                        <BiSolidEdit
                            className="text-success"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow4(item);
                              setData1(item);
                            }
                            }
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className="text-danger"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow5();
                                setData(item?._id)
                            }}

                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Package modal */}
        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header
            closeButton

          >
            <Modal.Title style={{ color: "black" }}>Add Our Services</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Service Heading</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                onChange={(e) => setAboutServiceHeading(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Service Title</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  onChange={(e) => setAboutServiceTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Service Title Description</label>
                <CKEditor editor={ClassicEditor}
                onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Service Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setAboutServiceImg(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Serice Title</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  onChange={(e) => setAboutServiceTitle1(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Serice Description</label>
                <CKEditor editor={ClassicEditor}
                onChange={handleChange1}
                />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
              onClick={AddAboutServiceDetails}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title style={{ color: "black" }}>Edit Our Services</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
              <label>Add Service Heading</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  value={AboutServiceHeading}
                  onChange={(e) => setAboutServiceHeading(e.target.value)}
                  />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
              <label>Add Service Title</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  value={AboutServiceTitle}
                  onChange={(e) => setAboutServiceTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Description</label>
                <CKEditor editor={ClassicEditor}
                data={AboutServiceDesc}
                onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
              <label>Add Service Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setAboutServiceImg(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
              <label>Add Serice Title</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  value={AboutServiceTitle1}
                  onChange={(e) => setAboutServiceTitle1(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Serice Description</label>
                <CKEditor editor={ClassicEditor}
                data={AboutServiceDesc1}
                onChange={handleChange1}
                />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button variant="" className="modal-add-btn"
              onClick={EditAboutService}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delet modal  */}
        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={DeleteAboutService}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default AboutOurServices