import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
// import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const BookingList = () => {

   
    // Booking list
    const [show, setShow] = useState();
    const [show4, setShow4] = useState();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);


 
    return (
        <div>
            <div className="col-lg-4 d-flex justify-content-center">
                <div class="input-group ">
                    <span class="input-group-text" id="basic-addon1">
                        <BsSearch />
                    </span>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                        aria-describedby="basic-addon1"
                    />
                </div>
            </div>
            <div className="customerhead p-2">

                <div className="d-flex justify-content-between align-items-center">
                    <h2 className="header-c ">Booking List</h2>
                    <button className="admin-add-btn" onClick={handleShow}>
                        Add Booking
                    </button>
                </div>

                <div className="mb-3">
                    <Table
                        responsive
                        bordered
                        style={{ width: "-webkit-fill-available" }}
                    >
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Service Name</th>
                                <th>Name</th>
                                <th>Phone Number</th>
                                <th>Email</th>
                                <th>Date</th>
                                <th>Location</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                        
                    <tr>
                      <td>1</td>
                      <td style={{ paddingTop: "20px" }}>Service Name</td>
                      <td style={{ paddingTop: "20px" }}>Name</td>
                      <td style={{ paddingTop: "20px" }}>Phone number</td>
                    <td style={{ paddingTop: "20px" }}>email</td>
                      <td style={{ paddingTop: "20px" }}>Date</td>
                      <td style={{ paddingTop: "20px" }}>Location</td>
                      <td>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <AiFillDelete
                              className="text-danger"
                              style={{ cursor: "pointer", fontSize: "20px" }}
                              onClick={() => {
                                handleShow4();
                                // setData(item?._id);
                              }}
                            />{" "}
                          </div>
                        </div>
                      </td>
                    </tr>
                        </tbody>
                    </Table>
                </div>
            </div>

            {/* Add booking list modal */}
            <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
                <Modal.Header
                    closeButton
                >
                    <Modal.Title style={{ color: "black" }}>Add Booking list</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <div className="row">
                        <div className="do-sear mt-2">
                        <label className="fw-bold">Choose Service</label>
                            <select className="form-control" 
                            //  onChange={(e) => setPackageName(e.target.value)}
                             >
                                <option>Select Type</option>
                  <option>Renovation</option>
                  <option>Interior</option>
                  <option>Fabrication</option>
                  <option>Roofing Solution</option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="do-sear mt-2">
                            <label>Name</label>
                            <input
                                type="text"
                                className="vi_0"
                                placeholder="Enter Name"
                                // onChange={(e) => setBookingUserName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="do-sear mt-2">
                            <label>Number</label>
                            <input
                                type="number"
                                className="vi_0"
                                placeholder="Enter Number"
                                // onChange={(e) => setBookingUserPhone(e.target.value)}

                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="do-sear mt-2">
                            <label>Email</label>
                            <input
                                type="text"
                                className="vi_0"
                                placeholder="Enter email"
                                // onChange={(e) => setBookingUserEmail(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="do-sear mt-2">
                            <label>Date</label>
                            <input
                                type="Date"
                                className="vi_0"
                                placeholder="Choose Date"
                                // onChange={(e) => setBookingDate(e.target.value)}
                            />
                        </div>
                    </div>

                 
                    <div className="row">
                        <div className="do-sear mt-2">
                            <label>Location</label>
                            <input
                                type="text"
                                className="vi_0"
                                placeholder="Location"
                                // onChange={(e) => setBookingDate(e.target.value)}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex">
                        <Button className="mx-2 modal-close-btn" variant="" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="mx-2 modal-add-btn" variant="" 
                        // onClick={AddBookinglist} 
                        >
                            Add
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

          
            {/* Delete booking */}
            <Modal
                show={show4}
                onHide={handleClose4}
                backdrop="static"
                keyboard={false}
                style={{ zIndex: "99999" }}
            >
                <Modal.Header
                    closeButton
                >
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <p className="fs-4" style={{ color: "red" }}>
                                Are you sure?
                                <br /> you want to delete this data?
                            </p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="" className="modal-close-btn" onClick={handleClose4}>
                        Close
                    </Button>
                    <Button variant="" className="modal-add-btn"
                    //  onClick={DeleteBooking}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default BookingList