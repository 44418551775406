import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image ,Container} from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import parse from 'html-react-parser';
import { IoEye } from "react-icons/io5";

const Aboutus = () => {

    //Add Modal for Slider
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (item) => {
        setShow3(true);
        setData1(item);
    };

    // Edit modal for  Slider
    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = (item) => {
        setAboutSliderImage1(item.AboutSliderImage1);
        setAboutSliderImage2(item.AboutSliderImage2);
        setAboutSliderImage3(item.AboutSliderImage3);
        setAboutSliderImage4(item.AboutSliderImage4);
        setAboutSliderImage5(item.AboutSliderImage5);
        setAboutSliderImage6(item.AboutSliderImage6);
        setAboutusTitle(item.AboutusTitle);
        setAboutusTagline(item.AboutusTagline);
        setAboutusDesc(item.AboutusDesc);
        setData1(item);
        setShow4(true);
    }

    // Delet modal for  Slider
    const [show5, setShow5] = useState();
    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);

    const handleChange = (e, editor) => {
        const data = editor.getData();
        setAboutusDesc(data);
    };


    // Image View 
    const [View, setView] = useState({});
    const [show6, setShow6] = useState(false);
    const handleClose6 = () => setShow6(false);
    const handleShow6 = () => setShow6(true);

    // ======INTEGRATION=======//

    // integrating post method
    const formdata = new FormData();
    const [AboutSliderImage1, setAboutSliderImage1] = useState("");
    const [AboutSliderImage2, setAboutSliderImage2] = useState("");
    const [AboutSliderImage3, setAboutSliderImage3] = useState("");
    const [AboutSliderImage4, setAboutSliderImage4] = useState("");
    const [AboutSliderImage5, setAboutSliderImage5] = useState("");
    const [AboutSliderImage6, setAboutSliderImage6] = useState("");
    const [AboutusTitle, setAboutusTitle] = useState("")
    const [AboutusTagline, setAboutusTagline] = useState("");
    const [AboutusDesc, setAboutusDesc] = useState("")

    const AddAboutusDetails = async () => {
        formdata.append("AboutSliderImage1", AboutSliderImage1);
        formdata.append("AboutSliderImage2", AboutSliderImage2);
        formdata.append("AboutSliderImage3", AboutSliderImage3);
        formdata.append("AboutSliderImage4", AboutSliderImage4);
        formdata.append("AboutSliderImage5", AboutSliderImage5);
        formdata.append("AboutSliderImage6", AboutSliderImage6);
        formdata.append("AboutusTitle", AboutusTitle);
        formdata.append("AboutusTagline", AboutusTagline);
        formdata.append("AboutusDesc", AboutusDesc);

        try {
            if (!AboutSliderImage1) {
                return alert("Please add Image");
            }
            if (!AboutSliderImage2) {
                return alert("Please add Image");
            }
            if (!AboutSliderImage3) {
                return alert("Please add Image");
            }
            if (!AboutSliderImage4) {
                return alert("Please add Image");
            }
            if (!AboutSliderImage5) {
                return alert("Please add Image");
            }
            if (!AboutSliderImage6) {
                return alert("Please add Image");
            }
            if (!AboutusTitle) {
                return alert("Please add Title");
            }
            if (!AboutusTagline) {
                return alert("Please add Tagline");
            }
            if (!AboutusDesc) {
                return alert("Please add Description");
            }
            const config = {
                url: "/admin/aboutus",
                method: "post",
                baseURL: "https://roomyy.life/api",
                header: { "content-type": "multipart/form-data" },
                data: formdata,
            };
            let res = await axios(config);
            if (res.status === 200) {
                alert(res.data.success);
                getAddAboutus();
                handleClose3();
            }
        } catch (error) {
            console.log(error);
            alert(error.response.data.msg);
        }
    };
    //integrating get  method
    const [AddAboutus, setAddAboutus] = useState([]);
    const getAddAboutus = async () => {
        try {
            let res = await axios.get(
                "https://roomyy.life/api/admin/getaboutus"
            );
            if (res.status === 200) {
                setAddAboutus(res.data.getaboutus);
            }
        } catch (error) {
            console.log(error);
        }
    };

    //delete method
    const [Data, setData] = useState("");
    const DeleteAboutus = async () => {
        try {
            const config = {
                url: "admin/Deleteaboutus/" + Data,
                method: "delete",
                baseURL: "https://roomyy.life/api/",
                header: { "content-type": "application/json" },
            };
            await axios(config).then((res) => {
                if (res.status === 200) {
                    alert("Successfully Delete");
                    getAddAboutus();
                    handleClose5();
                }
            });
        } catch (error) {
            console.log(error);
            alert(error.response.data.msg);
        }
    };

    //update method
    const [Data1, setData1] = useState("");
    const [show3, setShow3] = useState(false);

    const EditAboutus = async (e) => {
        e.preventDefault();
        formdata.append("AboutSliderImage1", AboutSliderImage1);
        formdata.append("AboutSliderImage2", AboutSliderImage2);
        formdata.append("AboutSliderImage3", AboutSliderImage3);
        formdata.append("AboutSliderImage4", AboutSliderImage4);
        formdata.append("AboutSliderImage5", AboutSliderImage5);
        formdata.append("AboutSliderImage6", AboutSliderImage6);
        formdata.append("AboutusTitle", AboutusTitle);
        formdata.append("AboutusTagline", AboutusTagline);
        formdata.append("AboutusDesc", AboutusDesc);

        formdata.append("id", Data1?._id);
        try {
            const config = {
                url: "admin/editaboutus",
                method: "put",
                baseURL: "https://roomyy.life/api/",
                header: { "content-type": "multipart/form-data" },
                data: formdata,
            };
            await axios(config).then((res) => {
                if (res.status === 200) {
                    alert("successfully Update");
                    handleClose4();
                    getAddAboutus();
                }
            });
        } catch (error) {
            console.log(error);
            alert(error.response.data.error);
        }
    };
    // };
    useEffect(() => {
        getAddAboutus();
    }, []);
    console.log(AddAboutus);

    // pagination
    const [currenpage, setCurrentpage] = useState(1);
    const recordsperpage = 5;
    const lastIndex = currenpage * recordsperpage;
    const firstIndex = lastIndex - recordsperpage;
    const records = AddAboutus.slice(firstIndex, lastIndex);
    const npages = Math.ceil(AddAboutus.length / recordsperpage);
    const numbers = [...Array(npages + 1).keys()].slice(1);

    function changePage(id) {
        setCurrentpage(id);
    }

    function prevpage() {
        if (currenpage !== firstIndex) {
            setCurrentpage(currenpage - 1);
        }
    }

    function nextpage() {
        if (currenpage !== lastIndex) {
            setCurrentpage(currenpage + 1);
        }
    }


    return (
        <div>
            <div className="col-lg-4 d-flex justify-content-center">
                <div class="input-group ">
                    <span class="input-group-text" id="basic-addon1">
                        <BsSearch />
                    </span>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                        aria-describedby="basic-addon1"
                    />
                </div>
            </div>
            <div className="customerhead p-2">
                <div className="d-flex justify-content-between align-items-center">
                    <h2 className="header-c ">About Us</h2>
                    <div className="d-flex gap-3">
                        <button className="admin-add-btn" onClick={handleShow3}>
                            Add About Us
                        </button>
                    </div>

                </div>

                <div className="mb-3">
                    <Table
                        responsive
                        bordered
                        style={{ width: "-webkit-fill-available" }}
                    >
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Tagline</th>
                                <th>Slider Image</th>
                                <th>Description</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>

                            {records?.map((item, i) => {
                                return (
                                    <tr key={i}>
                                    <td style={{ paddingTop: "20px" }}>{item.AboutusTitle}</td>
                                    <td style={{ paddingTop: "20px" }}>{parse(`<div>${item.AboutusTagline}</div>`)}</td>

                                        <td>
                                            <IoEye
                                                style={{ fontSize: "25px", color: "green" }}
                                                onClick={() => {
                                                    handleShow6();
                                                    setView(item);
                                                }}
                                            />
                                        </td>
                                        <td style={{ paddingTop: "20px" }}>{parse(`<div>${item.AboutusDesc}</div>`)}</td>
                                        <td>
                                            {" "}
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "20px",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <div>
                                                    <BiSolidEdit
                                                        className="text-success"
                                                        style={{ cursor: "pointer", fontSize: "20px" }}
                                                        onClick={() => {
                                                            handleShow4(item);
                                                            setData1(item);
                                                        }
                                                        }
                                                    />{" "}
                                                </div>
                                                <div>
                                                    <AiFillDelete
                                                        className="text-danger"
                                                        style={{ cursor: "pointer", fontSize: "20px" }}
                                                        onClick={() => {
                                                            handleShow5();
                                                            setData(item?._id);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>

                {/* Add Package modal for Slider */}
                <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
                    <Modal.Header
                        closeButton
                    >
                        <Modal.Title>Add About us</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Title</label>
                                <input
                                    type="text"
                                    className="vi_0"
                                    placeholder="Enter Banner Title"
                                onChange={(e) => setAboutusTitle(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Tagline</label>
                                <input
                                    type="text"
                                    className="vi_0"
                                    placeholder="Enter Banner Title"
                                onChange={(e) => setAboutusTagline(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Slider Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                onChange={(e) => setAboutSliderImage1(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Slider Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                onChange={(e) => setAboutSliderImage2(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Slider Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                onChange={(e) => setAboutSliderImage3(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Slider Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                onChange={(e) => setAboutSliderImage4(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Slider Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                onChange={(e) => setAboutSliderImage5(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Slider Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                onChange={(e) => setAboutSliderImage6(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Description</label>
                                <CKEditor editor={ClassicEditor}
                                onChange={handleChange}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex">
                            <Button
                                className="mx-2 modal-close-btn"
                                variant=""
                                onClick={handleClose3}
                            >
                                Close
                            </Button>
                            <Button
                                className="mx-2 modal-add-btn"
                                variant=""
                            onClick={AddAboutusDetails}
                            >
                                Add
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                {/* Edit Package modal for Slider */}
                <Modal
                    show={show4}
                    onHide={handleClose4}
                    backdrop="static"
                    keyboard={false}
                    style={{ zIndex: "99999" }}
                >
                    <Modal.Header
                        closeButton
                    >
                        <Modal.Title style={{ color: "black" }}>Edit Home Slider</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Title</label>
                                <input
                                    type="text"
                                    className="vi_0"
                                    placeholder="Enter Banner Title"
                                    value={AboutusTitle}
                                onChange={(e) => setAboutusTitle(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Tagline</label>
                                <input
                                    type="text"
                                    className="vi_0"
                                    placeholder="Enter Banner Title"
                                    value={AboutusTagline}
                                onChange={(e) => setAboutusTagline(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Slider Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                onChange={(e) => setAboutSliderImage1(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Slider Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                onChange={(e) => setAboutSliderImage2(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Slider Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                onChange={(e) => setAboutSliderImage3(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Slider Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                onChange={(e) => setAboutSliderImage4(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Slider Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                onChange={(e) => setAboutSliderImage5(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Slider Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                onChange={(e) => setAboutSliderImage6(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Description</label>
                                <CKEditor editor={ClassicEditor}
                                data={AboutusDesc}
                                onChange={handleChange}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant=""
                            className="modal-close-btn"
                            onClick={handleClose4}
                        >
                            Close
                        </Button>
                        <Button variant="" className="modal-add-btn"
                        onClick={EditAboutus}
                        >
                            Update
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*Delet Package modal for Slider */}
                <Modal
                    show={show5}
                    onHide={handleClose5}
                    backdrop="static"
                    keyboard={false}
                    style={{ zIndex: "99999" }}
                >
                    <Modal.Header
                        closeButton
                    >
                        <Modal.Title>Warning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="fs-4" style={{ color: "red" }}>
                                    Are you sure?
                                    <br /> you want to delete this data?
                                </p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant=""
                            className="modal-close-btn"
                            onClick={handleClose5}
                        >
                            Close
                        </Button>
                        <Button
                            variant=""
                            className="modal-add-btn"
                        onClick={DeleteAboutus}
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>


                   {/* Slider Image modal  */}
        <Modal
          show={show6}
          onHide={handleClose6}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title style={{ color: "black" }}>About us Slider Images</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className="row">
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/Aboutus/${View?.AboutSliderImage1}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/Aboutus/${View?.AboutSliderImage2}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/Aboutus/${View?.AboutSliderImage3}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/Aboutus/${View?.AboutSliderImage4}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/Aboutus/${View?.AboutSliderImage5}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/Aboutus/${View?.AboutSliderImage6}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
              </div>
            </Container>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose6}
            >
              Close
            </Button>

          </Modal.Footer>
        </Modal>
            </div>
        </div>
    )
}

export default Aboutus