import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import parse from 'html-react-parser';

const AdminInteriorBanner = () => {

    //Add Modal for Slider
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (item) => {
        setShow3(true);
        setData1(item);
    };

    // Edit modal for  Slider
    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = (item) => {
        setInteriorBannerImage(item.InteriorBannerImage);
        setInteriorBannerCaption(item.InteriorBannerCaption);
        setData1(item);
        setShow4(true);
    }

    // Delet modal for  Banner
    const [show5, setShow5] = useState();
    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);


    // ======INTEGRATION=======//

    // integrating post method
    const formdata = new FormData();
    const [InteriorBannerImage, setInteriorBannerImage] = useState("");
    const [InteriorBannerCaption, setInteriorBannerCaption] = useState("");

    const AddInteriorBannerDetails = async () => {
        formdata.append("InteriorBannerImage", InteriorBannerImage);
        formdata.append("InteriorBannerCaption", InteriorBannerCaption);

        try {
            if (!InteriorBannerImage) {
                return alert("Please add Image");
            }
            if (!InteriorBannerCaption) {
                return alert("Please add Caption");
            }
            const config = {
                url: "/admin/interiorbanner",
                method: "post",
                baseURL: "https://roomyy.life/api",
                header: { "content-type": "multipart/form-data" },
                data: formdata,
            };
            let res = await axios(config);
            if (res.status === 200) {
                alert(res.data.success);
                getAddInteriorBanner();
                handleClose3();
            }
        } catch (error) {
            console.log(error);
            alert(error.response.data.msg);
        }
    };
    //integrating get  method
    const [AddInteriorBanner, setAddInteriorBanner] = useState([]);
    const getAddInteriorBanner = async () => {
        try {
            let res = await axios.get(
                "https://roomyy.life/api/admin/getinteriorbanner"
            );
            if (res.status === 200) {
                setAddInteriorBanner(res.data.getinteriorbanner);
            }
        } catch (error) {
            console.log(error);
        }
    };

    //delete method
    const [Data, setData] = useState("");
    const DeleteInteriorBanner = async () => {
        try {
            const config = {
                url: "admin/Deleteinteriorbanner/" + Data,
                method: "delete",
                baseURL: "https://roomyy.life/api/",
                header: { "content-type": "application/json" },
            };
            await axios(config).then((res) => {
                if (res.status === 200) {
                    alert("Successfully Delete");
                    getAddInteriorBanner();
                    handleClose5();
                }
            });
        } catch (error) {
            console.log(error);
            alert(error.response.data.msg);
        }
    };

    //update method
    const [Data1, setData1] = useState("");
    const [show3, setShow3] = useState(false);

    const EditInterorBanner = async (e) => {
        e.preventDefault();
        formdata.append("InteriorBannerImage", InteriorBannerImage);
        formdata.append("InteriorBannerCaption", InteriorBannerCaption);

        formdata.append("id", Data1?._id);
        try {
            const config = {
                url: "admin/editinteriorbanner",
                method: "put",
                baseURL: "https://roomyy.life/api/",
                header: { "content-type": "multipart/form-data" },
                data: formdata,
            };
            await axios(config).then((res) => {
                if (res.status === 200) {
                    alert("successfully Update");
                    handleClose4();
                    getAddInteriorBanner();
                }
            });
        } catch (error) {
            console.log(error);
            alert(error.response.data.error);
        }
    };
    // };
    useEffect(() => {
        getAddInteriorBanner();
    }, []);
    console.log(AddInteriorBanner);

    // pagination
    const [currenpage, setCurrentpage] = useState(1);
    const recordsperpage = 5;
    const lastIndex = currenpage * recordsperpage;
    const firstIndex = lastIndex - recordsperpage;
    const records = AddInteriorBanner.slice(firstIndex, lastIndex);
    const npages = Math.ceil(AddInteriorBanner.length / recordsperpage);
    const numbers = [...Array(npages + 1).keys()].slice(1);

    function changePage(id) {
        setCurrentpage(id);
    }

    function prevpage() {
        if (currenpage !== firstIndex) {
            setCurrentpage(currenpage - 1);
        }
    }

    function nextpage() {
        if (currenpage !== lastIndex) {
            setCurrentpage(currenpage + 1);
        }
    }

    return (
        <div>
            <div className="col-lg-4 d-flex justify-content-center">
                <div class="input-group ">
                    <span class="input-group-text" id="basic-addon1">
                        <BsSearch />
                    </span>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                        aria-describedby="basic-addon1"
                    />
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <h2 className="header-c ">Interior Banner</h2>
                <div className="d-flex gap-3">
                    <button className="admin-add-btn" onClick={handleShow3}>
                        Add Interior Banner
                    </button>
                </div>

            </div>

            <div className="mb-3">
                <Table
                    responsive
                    bordered
                    style={{ width: "-webkit-fill-available" }}
                >
                    <thead>
                        <tr>
                            <th>Banner Image</th>
                            <th>Caption</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        {records?.map((item, i) => {
                            return (

                                <tr key={i}>
                                    <td>
                                        <Image
                                            src={`https://roomyy.life/InteriorBanner/${item?.InteriorBannerImage}`}
                                            alt="pic"
                                            style={{ width: "75px", height: "75px" }}
                                        />
                                    </td>
                                    <td style={{ paddingTop: "20px" }}>{item.InteriorBannerCaption}</td>
                                    <td>
                                        {" "}
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "20px",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <div>
                                                <BiSolidEdit
                                                    className="text-success"
                                                    style={{ cursor: "pointer", fontSize: "20px" }}
                                                    onClick={() => {
                                                        handleShow4(item);
                                                        setData1(item);
                                                    }
                                                    }
                                                />{" "}
                                            </div>
                                            <div>
                                                <AiFillDelete
                                                    className="text-danger"
                                                    style={{ cursor: "pointer", fontSize: "20px" }}
                                                    onClick={() => {
                                                        handleShow5();
                                                        setData(item?._id);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>

            {/* Add Package modal for Slider */}
            <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
                <Modal.Header
                    closeButton
                >
                    <Modal.Title>Add Interior Banner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="do-sear mt-2">
                            <label>Add Banner Image</label>
                            <input
                                type="file"
                                name=""
                                id=""
                                className="vi_0"
                                onChange={(e) => setInteriorBannerImage(e.target.files[0])}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="do-sear mt-2">
                            <label>Add Banner Caption</label>
                            <input
                                type="text"
                                className="vi_0"
                                placeholder="Enter Banner Title"
                                onChange={(e) => setInteriorBannerCaption(e.target.value)}
                            />
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex">
                        <Button
                            className="mx-2 modal-close-btn"
                            variant=""
                            onClick={handleClose3}
                        >
                            Close
                        </Button>
                        <Button
                            className="mx-2 modal-add-btn"
                            variant=""
                            onClick={AddInteriorBannerDetails}
                        >
                            Add
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* Edit Package modal for Slider */}
            <Modal
                show={show4}
                onHide={handleClose4}
                backdrop="static"
                keyboard={false}
                style={{ zIndex: "99999" }}
            >
                <Modal.Header
                    closeButton
                >
                    <Modal.Title style={{ color: "black" }}>Edit Interior Banner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="do-sear mt-2">
                            <label>Add Banner Image</label>
                            <input
                                type="file"
                                name=""
                                id=""
                                className="vi_0"
                                onChange={(e) => setInteriorBannerImage(e.target.files[0])}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="do-sear mt-2">
                            <label>Add Banner Caption</label>
                            <input
                                type="text"
                                className="vi_0"
                                placeholder="Enter Banner Title"
                                value={InteriorBannerCaption}
                                onChange={(e) => setInteriorBannerCaption(e.target.value)}
                            />
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant=""
                        className="modal-close-btn"
                        onClick={handleClose4}
                    >
                        Close
                    </Button>
                    <Button variant="" className="modal-add-btn"
                        onClick={EditInterorBanner}
                    >
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>

            {/*Delet Package modal for Slider */}
            <Modal
                show={show5}
                onHide={handleClose5}
                backdrop="static"
                keyboard={false}
                style={{ zIndex: "99999" }}
            >
                <Modal.Header
                    closeButton
                >
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <p className="fs-4" style={{ color: "red" }}>
                                Are you sure?
                                <br /> you want to delete this data?
                            </p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant=""
                        className="modal-close-btn"
                        onClick={handleClose5}
                    >
                        Close
                    </Button>
                    <Button
                        variant=""
                        className="modal-add-btn"
                        onClick={DeleteInteriorBanner}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            
        </div>
    )
}

export default AdminInteriorBanner