import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image ,Container} from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import parse from 'html-react-parser';
import { IoEye } from "react-icons/io5";

const AdminHomeCategory = () => {

  //Add Modal for Slider
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setData1(item);
  };

 // Edit modal 
 const [show4, setShow4] = useState(false);
 const handleClose4 = () => setShow4(false);
 const handleShow4 = (item) => {
   setHCTitle(item.HCTitle);
   setHCDesc(item.HCDesc);
   setHCSliderImage1(item.HCSliderImage1);
   setHCSliderImage2(item.HCSliderImage2);
   setHCSliderImage3(item.HCSliderImage3);
   setHCSliderImage4(item.HCSliderImage4);
   setHCSliderImage5(item.HCSliderImage5);
   setHCSliderImage6(item.HCSliderImage6);
   setData1(item);
   setShow4(true);
 }

  // Delet modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);


  const handleChange = (e, editor) => {
    const data = editor.getData();
    setHCDesc(data);
  };

  // Image View 
  const [View, setView] = useState({});
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ======INTEGRATION=======//

  // integrating post method
  const formdata = new FormData();
  const [HCSliderImage1, setHCSliderImage1] = useState("");
  const [HCSliderImage2, setHCSliderImage2] = useState("");
  const [HCSliderImage3, setHCSliderImage3] = useState("");
  const [HCSliderImage4, setHCSliderImage4] = useState("");
  const [HCSliderImage5, setHCSliderImage5] = useState("");
  const [HCSliderImage6, setHCSliderImage6] = useState("");
  const [HCTitle, setHCTitle] = useState("");
  const [HCDesc, setHCDesc] = useState("")

  const AddCat = async () => {
    formdata.append("HCSliderImage1", HCSliderImage1);
    formdata.append("HCSliderImage2", HCSliderImage2);
    formdata.append("HCSliderImage3", HCSliderImage3);
    formdata.append("HCSliderImage4", HCSliderImage4);
    formdata.append("HCSliderImage5", HCSliderImage5);
    formdata.append("HCSliderImage6", HCSliderImage6);
    formdata.append("HCTitle", HCTitle);
    formdata.append("HCDesc", HCDesc);

    try {
      if (!HCSliderImage1) {
        return alert("Please add Image");
      }
      if (!HCSliderImage2) {
        return alert("Please add Image");
      }
      if (!HCSliderImage3) {
        return alert("Please add Image");
      }
      if (!HCSliderImage4) {
        return alert("Please add Image");
      }
      if (!HCSliderImage5) {
        return alert("Please add Image");
      }
      if (!HCSliderImage6) {
        return alert("Please add Image");
      }

      if (!HCTitle) {
        return alert("Please add Title");
      }
      if (!HCDesc) {
        return alert("Please add Description");
      }
      const config = {
        url: "/admin/category",
        method: "post",
        baseURL: "https://roomyy.life/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddCategory();
        handleClose3();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddCategory, setAddCategory] = useState([]);
  const getAddCategory = async () => {
    try {
      let res = await axios.get(
        "https://roomyy.life/api/admin/getcategory"
      );
      if (res.status === 200) {
        setAddCategory(res.data.getcategory);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const DeleteCat = async () => {
    try {
      const config = {
        url: "admin/Deletecategory/" + Data,
        method: "delete",
        baseURL: "https://roomyy.life/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddCategory();
          handleClose5();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const [show3, setShow3] = useState(false);

  const EditCat = async (e) => {
    e.preventDefault();
    formdata.append("HCSliderImage1", HCSliderImage1);
    formdata.append("HCSliderImage2", HCSliderImage2);
    formdata.append("HCSliderImage3", HCSliderImage3);
    formdata.append("HCSliderImage4", HCSliderImage4);
    formdata.append("HCSliderImage5", HCSliderImage5);
    formdata.append("HCSliderImage6", HCSliderImage6);
    formdata.append("HCTitle", HCTitle);
    formdata.append("HCDesc", HCDesc);

    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "admin/editcategory",
        method: "put",
        baseURL: "https://roomyy.life/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose4();
          getAddCategory();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };
  useEffect(() => {
    getAddCategory();
  }, []);
  console.log(AddCategory);

  // pagination
  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 5;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  const records = AddCategory.slice(firstIndex, lastIndex);
  const npages = Math.ceil(AddCategory.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }
  return (
    <div>
      <div className="col-lg-4 d-flex justify-content-center">
        <div class="input-group ">
          <span class="input-group-text" id="basic-addon1">
            <BsSearch />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
          />
        </div>
      </div>
      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Home Category</h2>
          <div className="d-flex gap-3">
            <button className="admin-add-btn" onClick={handleShow3}>
              Add Home Category
            </button>
          </div>

        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Title</th>
                <th>Description</th>
                <th>Slider Image</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {records?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1 + firstIndex}</td>
                    <td style={{ paddingTop: "20px" }}>{item.HCTitle}</td>
                    <td style={{ paddingTop: "20px" }}>{parse(`<div>${item.HCDesc}</div>`)}</td>
                    <td>
                      <IoEye
                        style={{ fontSize: "25px", color: "green" }}
                        onClick={() => {
                          handleShow6();
                          setView(item);
                        }}
                      />
                    </td>
                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className="text-success"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow4(item);
                              setData1(item);
                            }
                            }
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className="text-danger"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow5();
                              setData(item?._id);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Package modal for Slider */}
        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header
            closeButton
          >
            <Modal.Title>Add Home Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Category Title</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                onChange={(e) => setHCTitle(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Category Description</label>
                <CKEditor editor={ClassicEditor}
                onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Slider Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                onChange={(e) => setHCSliderImage1(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Slider Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                onChange={(e) => setHCSliderImage2(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Slider Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                onChange={(e) => setHCSliderImage3(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Slider Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                onChange={(e) => setHCSliderImage4(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Slider Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                onChange={(e) => setHCSliderImage5(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Slider Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                onChange={(e) => setHCSliderImage6(e.target.files[0])}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
              onClick={AddCat}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title style={{ color: "black" }}>Edit Home Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Category Title</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  value={HCTitle}
                onChange={(e) => setHCTitle(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Category Description</label>
                <CKEditor editor={ClassicEditor}
                data={HCDesc}
                onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Slider Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                onChange={(e) => setHCSliderImage1(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Slider Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                onChange={(e) => setHCSliderImage2(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Slider Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                onChange={(e) => setHCSliderImage3(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Slider Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                onChange={(e) => setHCSliderImage4(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Slider Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                onChange={(e) => setHCSliderImage5(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Slider Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                onChange={(e) => setHCSliderImage6(e.target.files[0])}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button variant="" className="modal-add-btn"
            onClick={EditCat}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delet Package modal for Slider */}
        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
            onClick={DeleteCat}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>


             {/* Slider Image modal  */}
             <Modal
          show={show6}
          onHide={handleClose6}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title style={{ color: "black" }}>Category Slider Images</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className="row">
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/HomeCategory/${View?.HCSliderImage1}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/HomeCategory/${View?.HCSliderImage2}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/HomeCategory/${View?.HCSliderImage3}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/HomeCategory/${View?.HCSliderImage4}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/HomeCategory/${View?.HCSliderImage5}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Image
                    src={`https://roomyy.life/HomeCategory/${View?.HCSliderImage6}`}
                    alt="pic"
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
              </div>
            </Container>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose6}
            >
              Close
            </Button>

          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default AdminHomeCategory