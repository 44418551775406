import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image, Container } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import parse from 'html-react-parser';
import { IoEye } from "react-icons/io5";

const AboutShowcases = () => {

    //Add Modal for Slider
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (item) => {
        setShow3(true);
        setData1(item);
    };

    // Edit modal for  Slider
    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = (item) => {
        setAboutShowcaseImg1(item.AboutShowcaseImg1);
        setAboutShowcaseImg2(item.AboutShowcaseImg2);
        setAboutShowcaseImg3(item.AboutShowcaseImg3);
        setAboutShowcaseImg4(item.AboutShowcaseImg4);
        setAboutShowcaseImg5(item.AboutShowcaseImg5);
        setAboutShowcaseImg6(item.AboutShowcaseImg6);
        setAboutShowcaseTitle(item.AboutShowcaseTitle);
        setAboutShowcaseTagline(item.AboutShowcaseTagline);
        setData1(item);
        setShow4(true);
    }

    // Delet modal for  Slider
    const [show5, setShow5] = useState();
    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);


    // Image View 
    const [View, setView] = useState({});
    const [show6, setShow6] = useState(false);
    const handleClose6 = () => setShow6(false);
    const handleShow6 = () => setShow6(true);

    // ======INTEGRATION=======//

    // integrating post method
    const formdata = new FormData();
    const [AboutShowcaseImg1, setAboutShowcaseImg1] = useState("");
    const [AboutShowcaseImg2, setAboutShowcaseImg2] = useState("");
    const [AboutShowcaseImg3, setAboutShowcaseImg3] = useState("");
    const [AboutShowcaseImg4, setAboutShowcaseImg4] = useState("");
    const [AboutShowcaseImg5, setAboutShowcaseImg5] = useState("");
    const [AboutShowcaseImg6, setAboutShowcaseImg6] = useState("");
    const [AboutShowcaseTitle, setAboutShowcaseTitle] = useState("")
    const [AboutShowcaseTagline, setAboutShowcaseTagline] = useState("");

    const AddAboutShowacaseDetails = async () => {
        formdata.append("AboutShowcaseImg1", AboutShowcaseImg1);
        formdata.append("AboutShowcaseImg2", AboutShowcaseImg2);
        formdata.append("AboutShowcaseImg3", AboutShowcaseImg3);
        formdata.append("AboutShowcaseImg4", AboutShowcaseImg4);
        formdata.append("AboutShowcaseImg5", AboutShowcaseImg5);
        formdata.append("AboutShowcaseImg6", AboutShowcaseImg6);
        formdata.append("AboutShowcaseTitle", AboutShowcaseTitle);
        formdata.append("AboutShowcaseTagline", AboutShowcaseTagline);

        try {
            if (!AboutShowcaseTitle) {
                return alert("Please add Title");
            }
            if (!AboutShowcaseTagline) {
                return alert("Please add Tagline");
            }
            if (!AboutShowcaseImg1) {
                return alert("Please add Image");
            }
            if (!AboutShowcaseImg2) {
                return alert("Please add Image");
            }
            if (!AboutShowcaseImg3) {
                return alert("Please add Image");
            }
            if (!AboutShowcaseImg4) {
                return alert("Please add Image");
            }
            if (!AboutShowcaseImg5) {
                return alert("Please add Image");
            }
            if (!AboutShowcaseImg6) {
                return alert("Please add Image");
            }

            const config = {
                url: "/admin/aboutshowcase",
                method: "post",
                baseURL: "https://roomyy.life/api",
                header: { "content-type": "multipart/form-data" },
                data: formdata,
            };
            let res = await axios(config);
            if (res.status === 200) {
                alert(res.data.success);
                getAddAboutShowcase();
                handleClose3();
            }
        } catch (error) {
            console.log(error);
            alert(error.response.data.msg);
        }
    };
    //integrating get  method
    const [AddAboutShowcase, setAddAboutShowcase] = useState([]);
    const getAddAboutShowcase = async () => {
        try {
            let res = await axios.get(
                "https://roomyy.life/api/admin/getaboutshowcase"
            );
            if (res.status === 200) {
                setAddAboutShowcase(res.data.getaboutshowcase);
            }
        } catch (error) {
            console.log(error);
        }
    };

    //delete method
    const [Data, setData] = useState("");
    const DeleteAboutShowcase = async () => {
        try {
            const config = {
                url: "admin/Deleteaboutshowcase/" + Data,
                method: "delete",
                baseURL: "https://roomyy.life/api/",
                header: { "content-type": "application/json" },
            };
            await axios(config).then((res) => {
                if (res.status === 200) {
                    alert("Successfully Delete");
                    getAddAboutShowcase();
                    handleClose5();
                }
            });
        } catch (error) {
            console.log(error);
            alert(error.response.data.msg);
        }
    };

    //update method
    const [Data1, setData1] = useState("");
    const [show3, setShow3] = useState(false);

    const EditAboutShowcase = async (e) => {
        e.preventDefault();
        formdata.append("AboutShowcaseImg1", AboutShowcaseImg1);
        formdata.append("AboutShowcaseImg2", AboutShowcaseImg2);
        formdata.append("AboutShowcaseImg3", AboutShowcaseImg3);
        formdata.append("AboutShowcaseImg4", AboutShowcaseImg4);
        formdata.append("AboutShowcaseImg5", AboutShowcaseImg5);
        formdata.append("AboutShowcaseImg6", AboutShowcaseImg6);
        formdata.append("AboutShowcaseTitle", AboutShowcaseTitle);
        formdata.append("AboutShowcaseTagline", AboutShowcaseTagline);

        formdata.append("id", Data1?._id);
        try {
            const config = {
                url: "admin/editaboutshowcase",
                method: "put",
                baseURL: "https://roomyy.life/api/",
                header: { "content-type": "multipart/form-data" },
                data: formdata,
            };
            await axios(config).then((res) => {
                if (res.status === 200) {
                    alert("successfully Update");
                    handleClose4();
                    getAddAboutShowcase();
                }
            });
        } catch (error) {
            console.log(error);
            alert(error.response.data.error);
        }
    };
    // };
    useEffect(() => {
        getAddAboutShowcase();
    }, []);
    console.log(AddAboutShowcase);

    // pagination
    const [currenpage, setCurrentpage] = useState(1);
    const recordsperpage = 5;
    const lastIndex = currenpage * recordsperpage;
    const firstIndex = lastIndex - recordsperpage;
    const records = AddAboutShowcase.slice(firstIndex, lastIndex);
    const npages = Math.ceil(AddAboutShowcase.length / recordsperpage);
    const numbers = [...Array(npages + 1).keys()].slice(1);

    function changePage(id) {
        setCurrentpage(id);
    }

    function prevpage() {
        if (currenpage !== firstIndex) {
            setCurrentpage(currenpage - 1);
        }
    }

    function nextpage() {
        if (currenpage !== lastIndex) {
            setCurrentpage(currenpage + 1);
        }
    }


    return (
        <div>
            <div className="col-lg-4 d-flex justify-content-center">
                <div class="input-group ">
                    <span class="input-group-text" id="basic-addon1">
                        <BsSearch />
                    </span>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                        aria-describedby="basic-addon1"
                    />
                </div>
            </div>
            <div className="customerhead p-2">
                <div className="d-flex justify-content-between align-items-center">
                    <h2 className="header-c ">Showcases</h2>
                    <div className="d-flex gap-3">
                        <button className="admin-add-btn" onClick={handleShow3}>
                            Add Showcases
                        </button>
                    </div>

                </div>

                <div className="mb-3">
                    <Table
                        responsive
                        bordered
                        style={{ width: "-webkit-fill-available" }}
                    >
                        <thead>
                            <tr>
                                <th>Heading</th>
                                <th>Tagline</th>
                                <th>Images</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {records?.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td style={{ paddingTop: "20px" }}>{item.AboutShowcaseTitle}</td>
                                        <td style={{ paddingTop: "20px" }}>{item.AboutShowcaseTagline}</td>
                                        <td>
                                            <IoEye
                                                style={{ fontSize: "25px", color: "green" }}
                                                onClick={() => {
                                                    handleShow6();
                                                    setView(item);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            {" "}
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "20px",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <div>
                                                    <BiSolidEdit
                                                        className="text-success"
                                                        style={{ cursor: "pointer", fontSize: "20px" }}
                                                        onClick={() => {
                                                            handleShow4(item);
                                                            setData1(item);
                                                        }
                                                        }
                                                    />{" "}
                                                </div>
                                                <div>
                                                    <AiFillDelete
                                                        className="text-danger"
                                                        style={{ cursor: "pointer", fontSize: "20px" }}
                                                        onClick={() => {
                                                            handleShow5();
                                                            setData(item?._id);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>

                {/* Add Package modal for Slider */}
                <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
                    <Modal.Header
                        closeButton
                    >
                        <Modal.Title>Add Showcases</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Heading</label>
                                <input
                                    type="text"
                                    className="vi_0"
                                    placeholder="Enter Banner Title"
                                    onChange={(e) => setAboutShowcaseTitle(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Tagline</label>
                                <input
                                    type="text"
                                    className="vi_0"
                                    placeholder="Enter Banner Title"
                                    onChange={(e) => setAboutShowcaseTagline(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                    onChange={(e) => setAboutShowcaseImg1(e.target.files[0])}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                    onChange={(e) => setAboutShowcaseImg2(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                    onChange={(e) => setAboutShowcaseImg3(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                    onChange={(e) => setAboutShowcaseImg4(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                    onChange={(e) => setAboutShowcaseImg5(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                    onChange={(e) => setAboutShowcaseImg6(e.target.files[0])}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex">
                            <Button
                                className="mx-2 modal-close-btn"
                                variant=""
                                onClick={handleClose3}
                            >
                                Close
                            </Button>
                            <Button
                                className="mx-2 modal-add-btn"
                                variant=""
                                onClick={AddAboutShowacaseDetails}
                            >
                                Add
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                {/* Edit Package modal for Slider */}
                <Modal
                    show={show4}
                    onHide={handleClose4}
                    backdrop="static"
                    keyboard={false}
                    style={{ zIndex: "99999" }}
                >
                    <Modal.Header
                        closeButton
                    >
                        <Modal.Title style={{ color: "black" }}>Edit Showcases</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Heading</label>
                                <input
                                    type="text"
                                    className="vi_0"
                                    value={AboutShowcaseTitle}
                                    placeholder="Enter Banner Title"
                                    onChange={(e) => setAboutShowcaseTitle(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Tagline</label>
                                <input
                                    type="text"
                                    className="vi_0"
                                    value={AboutShowcaseTagline}
                                    placeholder="Enter Banner Title"
                                    onChange={(e) => setAboutShowcaseTagline(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                    onChange={(e) => setAboutShowcaseImg1(e.target.files[0])}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                    onChange={(e) => setAboutShowcaseImg2(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                    onChange={(e) => setAboutShowcaseImg3(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                    onChange={(e) => setAboutShowcaseImg4(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                    onChange={(e) => setAboutShowcaseImg5(e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="do-sear mt-2">
                                <label>Add Image</label>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    className="vi_0"
                                    onChange={(e) => setAboutShowcaseImg6(e.target.files[0])}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant=""
                            className="modal-close-btn"
                            onClick={handleClose4}
                        >
                            Close
                        </Button>
                        <Button variant="" className="modal-add-btn"
                            onClick={EditAboutShowcase}
                        >
                            Update
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*Delet Package modal for Slider */}
                <Modal
                    show={show5}
                    onHide={handleClose5}
                    backdrop="static"
                    keyboard={false}
                    style={{ zIndex: "99999" }}
                >
                    <Modal.Header
                        closeButton
                    >
                        <Modal.Title>Warning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="fs-4" style={{ color: "red" }}>
                                    Are you sure?
                                    <br /> you want to delete this data?
                                </p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant=""
                            className="modal-close-btn"
                            onClick={handleClose5}
                        >
                            Close
                        </Button>
                        <Button
                            variant=""
                            className="modal-add-btn"
                            onClick={DeleteAboutShowcase}
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>


                {/* Slider Image modal  */}
                <Modal
                    show={show6}
                    onHide={handleClose6}
                    backdrop="static"
                    keyboard={false}
                    style={{ zIndex: "99999" }}
                >
                    <Modal.Header
                        closeButton
                    >
                        <Modal.Title style={{ color: "black" }}>About Showcase Images</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <Image
                                        src={`https://roomyy.life/AboutShowcases/${View?.AboutShowcaseImg1}`}
                                        alt="pic"
                                        style={{ width: "100%", height: "200px" }}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Image
                                        src={`https://roomyy.life/AboutShowcases/${View?.AboutShowcaseImg2}`}
                                        alt="pic"
                                        style={{ width: "100%", height: "200px" }}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Image
                                        src={`https://roomyy.life/AboutShowcases/${View?.AboutShowcaseImg3}`}
                                        alt="pic"
                                        style={{ width: "100%", height: "200px" }}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Image
                                        src={`https://roomyy.life/AboutShowcases/${View?.AboutShowcaseImg4}`}
                                        alt="pic"
                                        style={{ width: "100%", height: "200px" }}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Image
                                        src={`https://roomyy.life/AboutShowcases/${View?.AboutShowcaseImg5}`}
                                        alt="pic"
                                        style={{ width: "100%", height: "200px" }}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <Image
                                        src={`https://roomyy.life/AboutShowcases/${View?.AboutShowcaseImg6}`}
                                        alt="pic"
                                        style={{ width: "100%", height: "200px" }}
                                    />
                                </div>
                            </div>
                        </Container>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant=""
                            className="modal-close-btn"
                            onClick={handleClose6}
                        >
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}

export default AboutShowcases