import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import parse from 'html-react-parser';

const AdminWhyChooseus = () => {

  //Add Modal for Slider
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setData1(item);
  };

  // Edit modal 
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (item) => {
    setHWCUTitle(item.HWCUTitle);
    setHWCUTagline(item.HWCUTagline);
    setHVideo(item.HVideo);
    setHWCUDesc(item.HWCUDesc);
    setData1(item);
    setShow4(true);
  }

  // Delet modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);


  const handleChange = (e, editor) => {
    const data = editor.getData();
    setHWCUDesc(data);
  };

  // Image View 
  const [View, setView] = useState({});
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // ======INTEGRATION=======//

  // integrating post method
  const formdata = new FormData();
  const [HWCUTitle, setHWCUTitle] = useState("");
  const [HWCUTagline, setHWCUTagline] = useState("");
  const [HVideo, setHVideo] = useState("");
  const [HWCUDesc, setHWCUDesc] = useState("");

  const AddWhychoose = async () => {
    formdata.append("HWCUTitle", HWCUTitle);
    formdata.append("HWCUTagline", HWCUTagline);
    formdata.append("HVideo", HVideo);
    formdata.append("HWCUDesc", HWCUDesc);

    try {
      if (!HWCUTitle) {
        return alert("Please add Title");
      }
      if (!HWCUTagline) {
        return alert("Please add Tagline");
      }
      if (!HVideo) {
        return alert("Please add edio");
      }
      if (!HWCUDesc) {
        return alert("Please add Description");
      }
      const config = {
        url: "/admin/whychoose",
        method: "post",
        baseURL: "https://roomyy.life/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddWhychooseus();
        handleClose3();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddWhychooseus, setAddWhychooseus] = useState([]);
  const getAddWhychooseus = async () => {
    try {
      let res = await axios.get(
        "https://roomyy.life/api/admin/getwhychoose"
      );
      if (res.status === 200) {
        setAddWhychooseus(res.data.getwhychoose);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const DeleteWhychooseus = async () => {
    try {
      const config = {
        url: "admin/Deletewhychoose/" + Data,
        method: "delete",
        baseURL: "https://roomyy.life/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddWhychooseus();
          handleClose5();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const [show3, setShow3] = useState(false);

  const EditWhychoose = async (e) => {
    e.preventDefault();
    formdata.append("HWCUTitle", HWCUTitle);
    formdata.append("HWCUTagline", HWCUTagline);
    formdata.append("HVideo", HVideo);
    formdata.append("HWCUDesc", HWCUDesc);
    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "admin/editwhychoose",
        method: "put",
        baseURL: "https://roomyy.life/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose4();
          getAddWhychooseus();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };
  useEffect(() => {
    getAddWhychooseus();
  }, []);
  console.log(AddWhychooseus);

  // pagination
  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 5;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  const records = AddWhychooseus.slice(firstIndex, lastIndex);
  const npages = Math.ceil(AddWhychooseus.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }

  return (
    <div>   <div className="col-lg-4 d-flex justify-content-center">
      <div class="input-group ">
        <span class="input-group-text" id="basic-addon1">
          <BsSearch />
        </span>
        <input
          type="text"
          class="form-control"
          placeholder="Search..."
          aria-describedby="basic-addon1"
        />
      </div>
    </div>
      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Why Choose Us</h2>
          <div className="d-flex gap-3">
            <button className="admin-add-btn" onClick={handleShow3}>
              Add Why Choose Us
            </button>
          </div>

        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Title</th>
                <th>Tagline</th>
                <th>Video</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {records?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1 + firstIndex}</td>
                    <td style={{ paddingTop: "20px" }}>{item.HWCUTitle}</td>
                    <td style={{ paddingTop: "20px" }}>{item.HWCUTagline}</td>
                    <td>
                      <video width="auto" height="150" controls>
                        <source
                          src={`https://roomyy.life/HomeWhyChooseus/${item?.HVideo}`}
                          type="video/mp4"
                        />
                      </video>
                     
                    </td>
                    <td style={{ paddingTop: "20px" }}>{parse(`<div>${item.HWCUDesc}</div>`)}</td>

                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className="text-success"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow4(item);
                              setData1(item);
                            }
                            }
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className="text-danger"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow5();
                              setData(item?._id);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Package modal for Slider */}
        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header
            closeButton
          >
            <Modal.Title>Add Why Choose Us</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Why Choose Us Title</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  onChange={(e) => setHWCUTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Why Choose Us Tagline</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  onChange={(e) => setHWCUTagline(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Vedio</label>
                <input
                  type="file"
                  accept=".mp4,.webm"
                  className="vi_0"
                  autoPlay loop muted 
                  onChange={(e) => setHVideo(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Category Description</label>
                <CKEditor editor={ClassicEditor}
                  onChange={handleChange}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={AddWhychoose}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title style={{ color: "black" }}>Edit Why Choose Us</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Why Choose Us Title</label>
                <input
                  type="text"
                  className="vi_0"
                  value={HWCUTitle}
                  placeholder="Enter Banner Title"
                  onChange={(e) => setHWCUTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Why Choose Us Tagline</label>
                <input
                  type="text"
                  className="vi_0"
                  value={HWCUTagline}
                  placeholder="Enter Banner Title"
                  onChange={(e) => setHWCUTagline(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Vedio</label>
                <input
                  type="file"
                  accept=".mp4,.webm"
                  className="vi_0"
                  autoPlay loop muted 
                  onChange={(e) => setHVideo(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Description</label>
                <CKEditor editor={ClassicEditor}
                  data={HWCUDesc}
                  onChange={handleChange}
                />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button variant="" className="modal-add-btn"
              onClick={EditWhychoose}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delet Package modal for Slider */}
        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header
            closeButton
          >
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={DeleteWhychooseus}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div></div>
  )
}

export default AdminWhyChooseus