import '../Styles/philosophy.css'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import parse from 'html-react-parser'
import axios from "axios";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
    };
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const Philosophy = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
    };

    // =======PHILOSOPHY=======//
    //integrating get  method
    const [AddPhilosophy, setAddPhilosophy] = useState([]);
    const getAddPhilosophy = async () => {
        try {
            let res = await axios.get(
                "https://roomyy.life/api/admin/getphilosophy"
            );
            if (res.status === 200) {
                setAddPhilosophy(res.data.getphilosophy);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getAddPhilosophy();
    }, []);

    return (
        <>

            <Container>
                <div className="row">
                    <div className="col-md-6">
                        <OwlCarousel
                            loop
                            margin={5}
                            nav
                            {...options}
                        >
                            {AddPhilosophy?.map((val, i) => {
                                return (
                                    <div class="item">
                                        <div class="card" >
                                            <div class="image-box">
                                                <img
                                                    src={`https://roomyy.life/HomePhilosophy/${val?.HPSliderImage1}`}
                                                    alt=""
                                                    className="aboutus-slider-img"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                );
                            })}
                            {AddPhilosophy?.map((val, i) => {
                                return (
                                    <div class="item">
                                        <div class="card" >
                                            <div class="image-box">
                                                <img
                                                    src={`https://roomyy.life/HomePhilosophy/${val?.HPSliderImage2}`}
                                                    alt=""
                                                    className="aboutus-slider-img"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                );
                            })}
                            {AddPhilosophy?.map((val, i) => {
                                return (
                                    <div class="item">
                                        <div class="card" >
                                            <div class="image-box">
                                                <img
                                                    src={`https://roomyy.life/HomePhilosophy/${val?.HPSliderImage3}`}
                                                    alt=""
                                                    className="aboutus-slider-img"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                );
                            })}

                            {AddPhilosophy?.map((val, i) => {
                                return (
                                    <div class="item">
                                        <div class="card" >
                                            <div class="image-box">
                                                <img
                                                    src={`https://roomyy.life/HomePhilosophy/${val?.HPSliderImage4}`}
                                                    alt=""
                                                    className="aboutus-slider-img"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                );
                            })}

                        </OwlCarousel>
                    </div>

                    <div className="col-md-6">
                        {AddPhilosophy?.map((val, i) => {
                            return (
                                <Box>
                                    <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                            <Tab label="Mission" {...a11yProps(1)} className='titles' />
                                            <Tab label="Vision" {...a11yProps(2)} className='titles' />
                                        </Tabs>
                                    </Box>

                                    <CustomTabPanel value={value} index={1} className='para'>
                                        {parse(`<div>${val?.MissionDesc}</div>`)}
                                    </CustomTabPanel>

                                    <CustomTabPanel value={value} index={0} className='para'>
                                        {parse(`<div>${val?.VisionDesc}</div>`)}
                                    </CustomTabPanel>

                                </Box>
                            );
                        })}

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Philosophy